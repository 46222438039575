import {
  Col,
  Collapse,
  CollapseVariation,
  Container,
  ControlledCollapse,
  Row,
  Typography,
} from '@objectedge/ziyou-storefront-ds';
import { MutableRefObject, useContext, useState } from 'react';
import { ProductContext } from '~/context';
import { ProductImage, ProductInterface } from '~/operations';
import Display, { Breakpoint } from '~/utils/Display';
import parseMinimumRequirements, { MinimumRequirement } from '~/utils/product';
import styles from './ProductDescription.module.scss';
import getTechnicalInfo, { CustomField } from './technical-info';

const TECHNICAL_INFO_TITLE = 'Informações técnicas';
const INSTALLATION_INFO_TITLE = 'Requisitos mínimos para instalação';

export interface ProductDescriptionProps {
  descriptionRef: MutableRefObject<any>;
}

type DescriptionItem = {
  label: string;
  value: any;
};

const getProductTechnicalInfo = (product: Partial<ProductInterface> = {}) => {
  const { attribute_set_name } = product;

  if (!attribute_set_name) return [];

  const format = (value: any, format?: Function): string => (!format ? value : format(value));

  const fieldGroups: CustomField[] = getTechnicalInfo(attribute_set_name);
  const infoList: Array<DescriptionItem> = [];

  fieldGroups.forEach((info: CustomField) => {
    if (info.label && product[info.field]) {
      infoList.push({ label: info.label, value: format(product[info.field], info.format) });
    }
  });

  return infoList;
};

export const ProductDescription = ({ descriptionRef }: ProductDescriptionProps) => {
  const product = useContext(ProductContext) || {};
  const [open, setOpen] = useState(true);
  const technicalInfo = getProductTechnicalInfo(product);
  const installationInfo = parseMinimumRequirements(product.minimum_requirements);

  const renderDescriptionItems = (infoList: Array<MinimumRequirement>) => {
    return (
      <Row className="description-items-container">
        {infoList?.map((info: DescriptionItem, index: number) => (
          <Col xs={12} lg={6} key={index}>
            <div className={styles['description-item']}>
              <Typography variant="h5" className={styles['description-item-label']}>
                {info.label}
              </Typography>
              <div className={styles['info-spacing']}></div>
              <Typography variant="h6" className={styles['description-item-detail']}>
                {info.value}
              </Typography>
            </div>
          </Col>
        ))}
      </Row>
    );
  };

  const renderDescriptionImage = (description_image: ProductImage | null | undefined) => {
    if (!description_image || !description_image.url) return null;
    return (
      <Col xs={12} lg={6} className={styles['image-column']}>
        <img className="img-responsive" src={description_image.url} />
      </Col>
    );
  };
  return (
    <div ref={descriptionRef} className={styles['product-description']}>
      <Display min={Breakpoint.lg}>
        <Container>
          {product?.description?.html && (
            <Row className={styles['description-row']}>
              <Col>
                <Typography variant="h2" className={styles['description-title']}>
                  Descrição
                </Typography>
                <Typography className={styles['description-text']}>
                  {<span dangerouslySetInnerHTML={{ __html: product?.description?.html }}></span>}
                </Typography>
              </Col>
            </Row>
          )}
          <Row className={styles['description-row']}>
            <Col xs={12} lg={6} className={styles['description-column']}>
              {technicalInfo?.length && (
                <>
                  <Typography variant="h5" className={styles['description-section-title']}>
                    {TECHNICAL_INFO_TITLE}
                  </Typography>
                  {renderDescriptionItems(technicalInfo)}
                </>
              )}
              {installationInfo?.length && (
                <>
                  <Typography variant="h5" className={styles['description-section-title'] + ' ' + 'mt-3'}>
                    {INSTALLATION_INFO_TITLE}
                  </Typography>
                  {renderDescriptionItems(installationInfo)}
                </>
              )}
            </Col>

            <Display min={Breakpoint.lg}>{renderDescriptionImage(product.description_image)}</Display>
          </Row>
        </Container>
      </Display>
      <Display max={Breakpoint.lg}>
        {product?.description && (
          <ControlledCollapse
            isOpen={open}
            toggle={() => setOpen(!open)}
            title="Descrição"
            variation={CollapseVariation.DARK}
          >
            {<span dangerouslySetInnerHTML={{ __html: product?.description?.html }}></span>}
          </ControlledCollapse>
        )}
        {technicalInfo?.length && (
          <Collapse title={TECHNICAL_INFO_TITLE} variation={CollapseVariation.DARK}>
            {renderDescriptionItems(technicalInfo)}
          </Collapse>
        )}
        {installationInfo?.length && (
          <Collapse title={INSTALLATION_INFO_TITLE} variation={CollapseVariation.DARK}>
            {renderDescriptionItems(installationInfo)}
            {renderDescriptionImage(product.description_image)}
          </Collapse>
        )}
      </Display>
    </div>
  );
};

export default ProductDescription;
