import Display, { Breakpoint } from '~/utils/Display';
import { FooterCompact, FooterDesktop, FooterMobile } from './subcomponents';

export interface FooterProps {
  compact?: boolean;
}

export const Footer = ({ compact = false }) =>
  compact ? (
    <FooterCompact />
  ) : (
    <>
      <Display max={Breakpoint.xl}>
        <FooterMobile />
      </Display>
      <Display min={Breakpoint.xl}>
        <FooterDesktop />
      </Display>
    </>
  );

export default Footer;
