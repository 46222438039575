import { Checkbox, Typography } from '@objectedge/ziyou-storefront-ds';
import { useContext, useEffect, useMemo } from 'react';
import { ProductContext } from '~/context';
import { ProductDetailsPageContext } from '~/context/pdp';
import { formatCurrency } from '~/utils/format/currency';
import { getPrices } from '~/utils/product';
import styles from './PlanSelector.module.scss';

export const PlanSelector = () => {
  const { variant, plan: selectedPlan, selectPlan } = useContext(ProductDetailsPageContext);
  const product = useContext(ProductContext) || {};
  const { plans, salePrice, listPrice } = useMemo(() => getPrices(variant || product), [variant, product]);

  const defaultPrice = salePrice ? salePrice : listPrice;
  const sortedOptions = plans && [...plans.options].sort((a, b) => (a.price >= b.price ? 1 : -1));

  useEffect(() => {
    if (selectedPlan === undefined) selectPlan(sortedOptions?.[0]);
    else if (selectedPlan === null) return;
    else if (!sortedOptions?.some((o) => o.id === selectedPlan?.id)) {
      selectPlan(
        sortedOptions?.find((o) => o.duration === selectedPlan?.duration) || (sortedOptions?.length && sortedOptions[0])
      );
    }
  }, [plans, sortedOptions]);

  if (!plans || !plans.options || plans.options.length === 0) return null;
  return (
    <div className={styles.planSelector}>
      <div className={styles.head}>
        <Typography variant="h5">OPÇÕES DE ASSINATURA</Typography>
      </div>
      <div className={styles.container}>
        {sortedOptions &&
          sortedOptions?.map((plan) => (
            <Checkbox className="d-flex" checked={selectedPlan?.id === plan.id} onChange={() => selectPlan(plan)}>
              <Typography variant="subtitle-1">
                {formatCurrency(plan.price, true, { minimumFractionDigits: 0 })}/mês - assinatura mínima de{' '}
                {plan.duration} meses
              </Typography>
            </Checkbox>
          ))}
        {product?.hide_price_if_plan_configured !== 1 && (
          <Checkbox className="d-flex" checked={!selectedPlan} onChange={() => selectPlan(null)}>
            <Typography variant="subtitle-1" className="mb-0">
              {formatCurrency(defaultPrice, true, { minimumFractionDigits: 0 })}/mês mínimo 1 mês de assinatura
            </Typography>
          </Checkbox>
        )}
      </div>
    </div>
  );
};

export default PlanSelector;
