import { Container, Typography } from '@objectedge/ziyou-storefront-ds';
import clsx from 'clsx';
import { getMediaPath } from '~/utils/getPublicPath';
import withComponentId from '~/utils/withComponentId';
import CategoryCarousel from '../CategoryCarousel';
import styles from './SubscriptionCategories.module.scss';

const categories = [
  {
    url_key: 'bike-spinning',
    name: 'Spinning',
    image: getMediaPath('/categories/categoria-spinning.webp'),
  },
  {
    url_key: 'esteiras',
    name: 'Esteiras',
    image: getMediaPath('/categories/categoria-esteira.webp'),
  },
  {
    url_key: 'bicicleta-ergometrica',
    name: 'Bicicleta Ergométrica',
    image: getMediaPath('/categories/categoria-bicicleta.webp'),
  },
  {
    url_key: 'eliptico',
    name: 'Elíptico',
    image: getMediaPath('/categories/categoria-eliptico.webp'),
  },
  {
    url_key: 'remo-indoor',
    name: 'Remo Indoor',
    image: getMediaPath('/categories/categoria-remo.webp'),
  },
  {
    url_key: 'estacao-de-musculacao',
    name: 'Estação de Musculação',
    image: getMediaPath('/categories/categoria-estacao.webp'),
  },
];

export const SubscriptionCategories = withComponentId(
  (_, ref) => (
    <div className={styles['main']} ref={ref}>
      <div className={clsx(styles['text-container'], 'mb-5')}>
        <Typography variant="h1" className="mb-2">
          Conheça nossas modalidades
        </Typography>
        <Typography variant="body-text-1" className="mb-0">
          Assinatura de equipamentos + aulas online
        </Typography>
      </div>
      <Container>
        <CategoryCarousel items={categories} />
      </Container>
    </div>
  ),
  'subscription-categories'
);

export default SubscriptionCategories;
