import { Container, GenericCarousel, Testimonial, Typography } from '@objectedge/ziyou-storefront-ds';
import clsx from 'clsx';
import styles from './Testimonials.module.scss';

export enum TestimonialTheme {
  LIGHT = 'light',
  DARK = 'dark',
}

type Testimonial = {
  authorName: string;
  testimonial: string;
};

export interface TestimonialProps {
  theme?: TestimonialTheme;
  testimonialsList?: Testimonial[];
  showRatings?: boolean;
}

export const Testimonials = ({ testimonialsList = [], theme, showRatings = false }: TestimonialProps) => {
  const sliderSettings = {
    infinite: true,
    centerMode: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: testimonialsList.length - 1,
        },
      },
    ],
  };

  return (
    <div className={clsx(styles['main'], styles[theme || 'light'])}>
      <Container fluid="xl" className={styles['slider-container']}>
        <div className={clsx(styles['title'], 'text-center')}>
          <Typography variant="h1">Opinião da comunidade ZiYou</Typography>
        </div>
        <GenericCarousel
          settings={sliderSettings}
          cards={testimonialsList.map(({ authorName, testimonial }, index) => (
            <>
              <Testimonial key={index + authorName} title={authorName} text={testimonial} showRatings={showRatings} />
            </>
          ))}
        />
      </Container>
    </div>
  );
};

export default Testimonials;
