import { Container, Typography } from '@objectedge/ziyou-storefront-ds';
import styles from './FooterCompact.module.scss';

export const FooterCompact = () => (
  <Container fluid className={styles.footer}>
    <Typography variant="h6" className={styles.title}>
      ZiYou, {new Date().getFullYear()}
    </Typography>
  </Container>
);

export default FooterCompact;
