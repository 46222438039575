import {
  Button,
  ButtonVariant,
  Heart24,
  Input,
  Send24,
  TruckDisabled24,
  Typography,
} from '@objectedge/ziyou-storefront-ds';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useWarnMeWhenAvailableMutation } from '~/operations';
import { email as storedEmail } from '~/state/user';
import styles from '../ProductAvailability.module.scss';

const EMAIL_REGEX =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

interface ProductUnavailableProps {
  sku: string;
  postalCode: string;
}

interface RegistrationFormData {
  email: string;
}

const ProductUnavailable = ({ sku, postalCode }: ProductUnavailableProps) => {
  const [registered, setRegistered] = useState(false);
  const email = storedEmail();
  const { handleSubmit, control, errors } = useForm<RegistrationFormData>({ defaultValues: { email } });
  const [warnMe, { loading }] = useWarnMeWhenAvailableMutation({
    onCompleted: () => {
      setRegistered(true);
    },
  });
  const onSubmit = ({ email }: RegistrationFormData) => {
    warnMe({
      variables: {
        postalCode: postalCode.replace(/[^\d]/g, ''),
        productId: sku,
        email,
      },
      optimisticResponse: {
        __typename: 'Mutation',
        warnMeWhenAvailable: true,
      },
    });
  };

  return (
    <div className={styles['product-availability']}>
      <div className="d-flex align-items-center mb-3">
        <TruckDisabled24 className="mr-2" />
        <Typography variant="body-text-2" className="color-dark mb-0">
          A ZiYou ainda não chegou na sua região. Nos informe seu contato para ser avisado quando chegarmos
        </Typography>
      </div>
      {!registered && (
        <form onSubmit={handleSubmit(onSubmit)} className="d-flex justify-content-between">
          <Controller
            render={({ onChange, onBlur, value }) => (
              <>
                <Input
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  placeholder="email"
                  className={styles['email-input']}
                  status={errors.email && 'danger'}
                  helperText={errors.email?.message}
                  disabled={loading}
                />
                <Button type="submit" disabled={loading} variant={ButtonVariant.DARK} className={styles.warnme}>
                  Me avisar
                </Button>
              </>
            )}
            name="email"
            control={control}
            rules={{ required: true, pattern: { value: EMAIL_REGEX, message: 'Email Inválido' } }}
          />
        </form>
      )}
      {registered && (
        <>
          <div className="d-flex align-items-center mb-3">
            <Send24 className="mr-2" />
            <Typography variant="body-text-2" className="color-dark mb-0">
              E-mail cadastrado! Você será avisado a qualquer nova notícia sobre a expansão do ZiYou.
            </Typography>
          </div>
          <div className="d-flex align-items-center mb-4">
            <Heart24 className="mr-2" />
            <Typography variant="body-text-2" className="color-dark mb-0">
              Obrigado pelo interesse!
            </Typography>
          </div>
        </>
      )}
    </div>
  );
};

export { ProductUnavailable };
