import { ZonedDateTime } from '@js-joda/core';

export interface FormatDateOptions {
  utc?: boolean;
}

/**
 * Format a date to the locale format
 * @param date Date as string (parseable to Date) or a date
 */
export const formatDate = (date: string | Date | null | undefined, { utc = false }: FormatDateOptions = {}): string => {
  if (typeof date === 'string') date = new Date(date);
  if (date) {
    const options: Intl.DateTimeFormatOptions = {};
    if (utc) options.timeZone = 'UTC';
    const formatedDate = new Intl.DateTimeFormat('pt-br', options).format(date);
    return formatedDate;
  }
  return '';
};

/**
 * Check if a given date is in past
 * @param date The date. Can be a string or an instance of Date
 * @returns true if the date is in past, false otherwise
 */
export const hasPast = (date: string | Date | null | undefined) => {
  if (date == null) return false;
  const parsedDate = ZonedDateTime.parse(typeof date === 'string' ? date : date.toISOString());
  return parsedDate ? parsedDate.isBefore(ZonedDateTime.now()) : false;
};
