import { Change64, Delivery64, Support64, Typography, Wallet64 } from '@objectedge/ziyou-storefront-ds';
import clsx from 'clsx';
import styles from './FeaturesList.module.scss';

export enum Theme {
  LIGHT = 'light',
  DARK = 'dark',
}

export interface FeaturesListProps {
  theme?: Theme;
}

export const FeaturesList = ({ theme }: FeaturesListProps) => (
  <div className={clsx(styles['main'], styles[theme || 'light'])}>
    <div className={styles['block']}>
      {' '}
      <div className={styles['card']}>
        <Change64 className={styles['icon']} />
        <div className={styles['text-container']}>
          <Typography style={{ fontFamily: 'saira' }} className={styles['title']} weight="bold">
            LIBERDADE
          </Typography>
          <Typography style={{ fontFamily: 'saira' }} className={styles['text']} variant="body-text-1">
            Receba seu equipamento onde você estiver e pratique sua atividade física como e quando quiser.
          </Typography>
        </div>
      </div>
      <div className={styles['card']}>
        <Delivery64 className={styles['icon']} />
        <div className={styles['text-container']}>
          <Typography style={{ fontFamily: 'saira' }} className={styles['title']} weight="bold">
            COMODIDADE
          </Typography>
          <Typography style={{ fontFamily: 'saira' }} className={styles['text']} variant="body-text-1">
            Você agenda a data de entrega e nós levamos o equipamento montado, ele chega já pronto para você usar.
          </Typography>
        </div>
      </div>
    </div>
    <div className={styles['block']}>
      <div className={styles['card']}>
        <Wallet64 className={styles['icon']} />
        <div className={styles['text-container']}>
          <Typography style={{ fontFamily: 'saira' }} className={styles['title']} weight="bold">
            CONTEÚDO
          </Typography>
          <Typography style={{ fontFamily: 'saira' }} className={styles['text']} variant="body-text-1">
            Tenha acesso livre a centenas de conteúdos e aulas para você se manter em movimento.
          </Typography>
        </div>
      </div>
      <div className={styles['card']}>
        <Support64 className={styles['icon']} />
        <div className={styles['text-container']}>
          <Typography style={{ fontFamily: 'saira' }} className={styles['title']} weight="bold">
            ATENDIMENTO
          </Typography>
          <Typography style={{ fontFamily: 'saira' }} className={styles['text']} variant="body-text-1">
            Conte com o nosso time de especialistas para te dar suporte durante toda a sua jornada.
          </Typography>
        </div>
      </div>
    </div>
  </div>
);

export default FeaturesList;
