import { makePersistentVar } from '~/utils/variables';

export const customerToken = makePersistentVar<string>('customerToken');

export const cachePolicies = {
  Query: {
    fields: {
      customer: {
        read() {
          return customerToken();
        },
      },
    },
  },
};
