import { useApolloClient, useLazyQuery } from '@apollo/client';
import {
  Bike30,
  Cart32,
  Close32,
  Collapse,
  Container,
  Eliptico30,
  Esteiras30,
  Hamburger32,
  Logo,
  LogoSize,
  Remo30,
  Search32,
  Spinning30,
  Station30,
  Typography,
  User32,
} from '@objectedge/ziyou-storefront-ds';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { InputSearch } from '~/components/search';
import { GetCustomerDocument, useRevokeCustomerTokenMutation } from '~/operations';
import Display, { Breakpoint, useBreakpoint } from '~/utils/Display';
import { useCartContext } from '~/utils/useCart';
import { useCookiesContext } from '~/utils/useCookies';
import styles from './HeaderMobile.module.scss';
import cookies from 'js-cookie';

interface Name {
  firstname: string;
}

const categories = [
  {
    url_key: '/categoria/esteiras',
    name: 'Esteiras',
    icon: <Esteiras30 className={styles['icon']} />,
  },
  {
    url_key: '/categoria/bike-spinning',
    name: 'Spinning',
    icon: <Spinning30 className={styles['icon']} />,
  },
  {
    url_key: '/categoria/bicicleta-ergometrica',
    name: 'Bicicleta Ergométrica',
    icon: <Bike30 className={styles['icon']} />,
  },
  {
    url_key: '/categoria/eliptico',
    name: 'Elíptico',
    icon: <Eliptico30 className={styles['icon']} />,
  },
  {
    url_key: '/categoria/remo-indoor',
    name: 'Remo Indoor',
    icon: <Remo30 className={styles['icon']} />,
  },
  {
    url_key: '/categoria/estacao-de-musculacao',
    name: 'Estação de Musculação',
    icon: <Station30 className={styles['icon']} />,
  },
];
export const HeaderMobile = () => {
  const Router = useRouter();
  const { cache } = useApolloClient();
  const breakpoint = useBreakpoint();
  const [getCustomer] = useLazyQuery<{ customer: Name }>(GetCustomerDocument);

  const { actions: cartActions } = useCartContext();
  const { state: cookie, actions: cookieActions } = useCookiesContext();

  const [revokeCustomerToken] = useRevokeCustomerTokenMutation();
  const CORPORATIVO_URL = process.env.CORPORATIVO_URL;
  const PRODUTOS_PROPRIOS_URL = process.env.PRODUTOS_PROPRIOS_URL;
  const [menu, setMenu] = useState(false);
  const [search, setSearch] = useState(false);
  const [firstName, setFirstName] = useState('');

  const logout = useCallback(() => {
    revokeCustomerToken();
    cookieActions.updateUserTokenCookie('');
    cookieActions.updateUserEmail('');
    cache.evict({ id: 'ROOT_QUERY', fieldName: 'customer' });
    setMenu(false);
    cartActions.initCart(true); // reset cart
    Router.push('/');
  }, []);

  useEffect(() => {
    if (cookies.get(process.env.AUTH_COOKIE_KEY!)) {
      getCustomer().then(({ data }) => {
        setFirstName(data?.customer?.firstname || '');
      });
    }
  }, []);

  return (
    <Container
      fluid
      className={menu ? `fixed-top ${styles.main} ${styles.mobileHeaderColor}` : `fixed-top ${styles.main}`}
    >
      <div>
        <Container fluid className={styles.header}>
          {menu ? '' : <Hamburger32 id="header-mobi-open-menu" onClick={() => setMenu(!menu)} />}
          <div className={clsx(styles.logo, menu && styles['menu-open'])}>
            <Link href="/">
              <a id="header-mobi-logo">
                <Logo size={LogoSize.SMALL} />
              </a>
            </Link>
          </div>
          {menu ? (
            <div className={styles.headerCloseBIcon}>
              <Close32 id="header-mobi-close-menu" className={styles['icon']} onClick={() => setMenu(!menu)} />
            </div>
          ) : (
            <>
              <Search32 id="header-mobi-icon-search" className="mr-4" onClick={() => setSearch(!search)} />
              <Link href="/carrinho">
                <a id="header-mobi-mini-cart">
                  <Cart32 items={cartActions.getTotalItemsInTheCart()} />
                </a>
              </Link>
            </>
          )}
        </Container>
        {menu ? (
          <div className={styles['menu']}>
            {firstName ? (
              <div className={styles['column-user']}>
                (
                <Typography className={styles['text']} variant="subtitle-2">
                  Olá {firstName}
                </Typography>
                )
                <User32 id="header-mobi-icon-user" className="d-block d-md-none" />
              </div>
            ) : (
              <Link href="/login">
                <a id="header-mobi-link-login" className={styles['column-user']}>
                  <Typography className={styles['text']} variant="subtitle-2">
                    Entre ou cadastre-se
                  </Typography>
                  <User32 />
                </a>
              </Link>
            )}
            {cookie.token ? (
              <div className={styles['user-menu']}>
                <div>
                  <div className={styles['column-user-menu']}>
                    <Link href="/minha-conta">
                      <a id="header-mobi-link-perfil">
                        <Typography className={styles['text-user']} variant="subtitle-2" weight="bold">
                          Perfil
                        </Typography>
                      </a>
                    </Link>
                  </div>
                  <div className={styles['column-user-menu']}>
                    <Link href="/minha-conta/minhas-assinaturas">
                      <a id="header-mobi-link-minhas-assinaturas">
                        <Typography className={styles['text-user']} variant="subtitle-2" weight="bold">
                          Minhas assinaturas
                        </Typography>
                      </a>
                    </Link>
                  </div>
                  <div id="header-mobi-link-sair" className={styles['column-user-menu']} onClick={logout}>
                    <Typography className={styles['text-user']} variant="subtitle-2" weight="bold">
                      Sair
                    </Typography>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
            <div className={styles.subMenu}>
              <Link href="/sobre-a-ziyou">
                <a id="header-mobi-link-conheca" className={styles['column']}>
                  <Typography className={styles['text']} variant="subtitle-2">
                    Sobre
                  </Typography>
                </a>
              </Link>
              <Link href="/como-funciona">
                <a id="header-mobi-link-assinaturas" className={styles['column']}>
                  <Typography className={styles['text']} variant="subtitle-2">
                    Como funciona
                  </Typography>
                </a>
              </Link>
              <Link href="/club">
                <a id="header-mobi-link-club" className={styles['column']}>
                  <Typography className={styles['text']} variant="subtitle-2">
                    Ziyou Club
                  </Typography>
                </a>
              </Link>
              <Collapse
                title="Todas as Modalidades"
                className={styles.collapseTitle}
                collapseClassName={styles.collapse}
                isOpenDefault={breakpoint === Breakpoint.xs}
              >
                <div className={styles['user-menu']}>
                  {categories.map((category, idx) => (
                    <div className={styles['column-user-menu']} key={idx}>
                      <Link href={category.url_key} as={category.url_key}>
                        <a id="header-mobi-link-perfil" onClick={() => setMenu(false)}>
                          {category.icon}
                          <Typography
                            className={styles['text-user']}
                            variant="subtitle-2"
                            weight="bold"
                            component="span"
                          >
                            {category.name}
                          </Typography>
                        </a>
                      </Link>
                    </div>
                  ))}
                </div>
              </Collapse>
              <Display min={Breakpoint.sm}>
                <Link href={PRODUTOS_PROPRIOS_URL || ''}>
                  <a target="_blank" id="header-mobi-link-nova-bike" className={styles['column']}>
                    <Typography className={styles['text']} variant="subtitle-2">
                      Produtos Próprios
                    </Typography>
                  </a>
                </Link>
              </Display>
              <Link href={CORPORATIVO_URL || ''}>
                <a id="header-mobi-link-corporativo" className={styles['column']}>
                  <Typography className={styles['text']} variant="subtitle-2">
                    Para empresas
                  </Typography>
                </a>
              </Link>
            </div>
          </div>
        ) : null}
        <InputSearch search={search} closeDropdown={() => setSearch(!search)} />
      </div>
    </Container>
  );
};

export default HeaderMobile;
