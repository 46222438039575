import { PropsWithoutRef } from 'react';
import { createRef, forwardRef, useEffect } from 'react';

export interface WithComponentId {
  componentId?: string;
}

export const withComponentId =
  <P extends object, T extends HTMLDivElement>(
    Component: React.ForwardRefRenderFunction<T, P>,
    prefix: string = ''
  ): React.FC<P & WithComponentId> =>
  ({ componentId, ...props }: WithComponentId) => {
    const ref = createRef<T>();

    const ReferencedComponent = forwardRef(Component);

    useEffect(() => {
      ref.current && ref.current.setAttribute('id', (prefix ? prefix + '-' : '') + componentId);
    }, [ref.current]);

    return <ReferencedComponent {...(props as PropsWithoutRef<P>)} ref={ref} />;
  };

export default withComponentId;
