import { CheckGreen24, Face24, Link24, Share24, Whats24 } from '@objectedge/ziyou-storefront-ds';
import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { FacebookShareButton, WhatsappShareButton } from 'react-share';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import styles from './ProductShare.module.scss';

export interface ProductShareProps {
  product: string;
  className?: string;
  toggleClassName?: string;
  url?: string;
}

export const ProductShare = ({ product, url, className, toggleClassName }: ProductShareProps) => {
  const [shareDialog, setShareDialog] = useState(false);
  const [copy, setCopy] = useState(false);

  const shareUrl = url || (typeof window !== 'undefined' && window.location.href) || '';

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(`${window.location}`);
    setCopy(!copy);
  };

  const share = useCallback(() => {
    if (navigator.share)
      navigator.share({ title: 'ZiYou', text: `Olha o que eu achei na ZiYou! ${product}`, url: shareUrl });
    else {
      setShareDialog(!shareDialog);
      if (shareDialog) setCopy(false); // reset copy when closing dialog
    }
  }, [shareDialog]);

  return (
    <Dropdown isOpen={shareDialog} toggle={share} className={clsx(styles.share, className)}>
      <DropdownToggle tag="span" className={clsx(styles.icon, toggleClassName)}>
        <Share24 />
      </DropdownToggle>
      <DropdownMenu className={styles.items}>
        <DropdownItem
          tag={() => (
            <FacebookShareButton url={shareUrl} quote={`Olha o que eu achei na ZiYou! ${product}`}>
              <Face24 />
            </FacebookShareButton>
          )}
        />
        <DropdownItem
          tag={() => (
            <WhatsappShareButton url={shareUrl}>
              <Whats24 />
            </WhatsappShareButton>
          )}
        />
        {typeof navigator !== 'undefined' && navigator.clipboard && (
          <DropdownItem toggle={false} onClick={copyToClipboard}>
            {copy ? <CheckGreen24 /> : <Link24 />}
          </DropdownItem>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

export default ProductShare;
