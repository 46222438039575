import { ProductInterface } from '~/operations';

// types
export type CustomField = {
  field: keyof ProductInterface;
  label: string;
  format?: (val: any) => string;
};

export type ProductCustomFields = {
  [key: string]: FieldGroups;
};

export type FieldGroups = {
  technicalInfo: Array<TechnicalInfo>;
  installationInfo: Array<any>;
};

export type TechnicalInfo = {
  [key: string]: Array<string>;
};

// helper functions for formatting values
const booleanFormatter = (val: any): string => (val ? 'Sim' : 'Não');
const weightFormatter = (val: any): string => `${val} kg`;
const speedFormatter = (val: any): string => `${val} m/s`;

// mappings
const customFields: CustomField[] = [
  {
    field: 'max_speed',
    label: 'Velocidade Máxima',
  },
  {
    field: 'min_speed',
    label: 'Velocidade Mínima',
  },
  {
    field: 'running_surface',
    label: 'Superfície de Corrida',
  },
  {
    field: 'inclination',
    label: 'Inclinação',
  },
  {
    field: 'supported_weight',
    label: 'Peso Suportado',
  },
  {
    field: 'functions',
    label: 'Funções',
  },
  {
    field: 'heart_monitoring',
    label: 'Monitoramento Cardíaco',
    format: booleanFormatter,
  },
  {
    field: 'motor_type',
    label: 'Tipo de Motor e Potência',
  },
  {
    field: 'num_of_programs',
    label: 'Quantidade de Programas',
  },
  {
    field: 'foldable',
    label: 'Dobrável',
    format: booleanFormatter,
  },
  {
    field: 'has_security_key',
    label: 'Possui chave de segurança',
    format: booleanFormatter,
  },
  {
    field: 'absorb_type',
    label: 'Tipo de Amortecimento',
  },
  {
    field: 'panel',
    label: 'Tipo de Painel',
  },
  {
    field: 'inertia_wheel_weight',
    label: 'Peso da Roda de Inércia',
  },
  {
    field: 'settings',
    label: 'Regulagens',
  },
  {
    field: 'product_weight',
    label: 'Peso do Produto',
  },
  {
    field: 'settings_system',
    label: 'Sistema de Regulagem',
  },
  {
    field: 'resistence_levels',
    label: 'Níveis de Resistência',
  },
  {
    field: 'pace_dimension',
    label: 'Dimensão da Passada do Movimento',
  },
  {
    field: 'max_weight',
    label: 'Peso Máximo do Usuário Suportado',
  },
  {
    field: 'num_weight_towers',
    label: 'Quantidade de Torres de Peso',
  },
  {
    field: 'num_possible_exercises',
    label: 'Número de Exercícios Possíveis',
  },
  {
    field: 'tower_load',
    label: 'Carga da Torre',
  },
  {
    field: 'structure_composition',
    label: 'Composição do Estofado e Pintura',
  },
  {
    field: 'included_items',
    label: 'Itens Inclusos',
  },
  {
    field: 'max_load',
    label: 'Carga Suportada',
  },
  {
    field: 'max_load_per_exercise',
    label: 'Suporta quantos Kg em Anilhas em cada Exercício',
  },
  {
    field: 'inclination_levels',
    label: 'Níveis de Inclinação e Declinação',
  },
  {
    field: 'weight_units',
    label: 'Anilhas',
  },
];

const technicalInfo: TechnicalInfo = {
  Esteira: [
    // esteira
    'max_speed',
    'min_speed',
    'running_surface',
    'inclination',
    'supported_weight',
    'functions',
    'heart_monitoring',
    'motor_type',
    'num_of_programs',
    'foldable',
    'has_security_key',
    'absorb_type',
    'max_weight',
  ],
  'Bicicleta Ergométrica': [
    // bicicleta
    'supported_weight',
    'functions',
    'heart_monitoring',
    'num_of_programs',
    'panel',
    'product_weight',
    'settings_system',
    'resistence_levels',
    'max_weight',
  ],
  Elíptico: [
    // eliptico
    'supported_weight',
    'functions',
    'heart_monitoring',
    'num_of_programs',
    'product_weight',
    'settings_system',
    'resistence_levels',
    'pace_dimension',
    'max_weight',
  ],
  Spinning: [
    // spinning
    'supported_weight',
    'panel',
    'inertia_wheel_weight',
    'settings',
    'product_weight',
  ],
  'Estação de Musculação': [
    // estação
    'inclination',
    'supported_weight',
    'product_weight',
    'max_weight',
    'num_weight_towers',
    'num_possible_exercises',
    'tower_load',
    'structure_composition',
    'included_items',
    'max_load',
    'max_load_per_exercise',
    'inclination_levels',
    'weight_units',
  ],
  'Remo Indoor': [
    // remo indoor
    'supported_weight',
    'functions',
    'foldable',
    'product_weight',
    'resistence_levels',
    'max_weight',
  ],
};

const getTechnicalInfo = (type: string): CustomField[] => {
  const ti = technicalInfo[type] || [];

  const fields: CustomField[] = [];
  ti.forEach((field) => {
    const f = customFields.find((prop) => field === prop.field);
    if (f) fields.push(f);
  });

  return fields;
};

export default getTechnicalInfo;
