import clsx from 'clsx';
import { Row, Col, Input, Button, Typography } from '@objectedge/ziyou-storefront-ds';
import styles from './Newsletter.module.scss';

export interface NewsletterProps {
  className?: string;
}

export const Newsletter = ({ className }: NewsletterProps) => (
  <div className={clsx(styles.newsletter, className)}>
    <Typography variant="h5">Receba nossas novidades</Typography>
    <Input className="mb-0" />
    <Row className="flex-column-reverse flex-sm-row">
      <Col className="mt-3 mt-md-0">
        <p>
          Para saber como nos usamos suas informações, veja nossa{' '}
          <a target="_blank" href="/politica-de-privacidade">
            política de privacidade
          </a>
        </p>
      </Col>
      <Col>
        <Button block>Cadastrar</Button>
      </Col>
    </Row>
  </div>
);

export default Newsletter;
