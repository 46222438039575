import { Button, ButtonSize, Typography } from '@objectedge/ziyou-storefront-ds';
import Link from 'next/link';
import { Breakpoint, Display } from '~/utils/Display';
import { getMediaPath } from '~/utils/getPublicPath';
import styles from './MainBanner.module.scss';

export const MainBanner = () => (
  <div className={styles['main']}>
    <video
      autoPlay
      loop
      muted
      poster={getMediaPath('/homepage/main-banner/banner-principal-still.webp')}
      playsInline
      className={styles.video}
    >
      <Display min={Breakpoint.lg}>
        {/* <source src={getMediaPath('/homepage/main-banner/video-mainbanner-desktop-720p.webp')} type="video/webp" /> */}
        <source src={getMediaPath('/homepage/main-banner/video-mainbanner-desktop-720p.webm')} type="video/webm" />
      </Display>
      <Display min={Breakpoint.md} max={Breakpoint.lg}>
        {/* <source src={getMediaPath('/homepage/main-banner/video-mainbanner-desktop-480p.webp')} type="video/webp" /> */}
        <source src={getMediaPath('/homepage/main-banner/video-mainbanner-desktop-480p.webm')} type="video/webm" />
      </Display>
      <Display max={Breakpoint.md}>
        {/* <source src={getMediaPath('/homepage/main-banner/video-mainbanner-mobile-360p.webp')} type="video/webp" /> */}
        <source src={getMediaPath('/homepage/main-banner/video-mainbanner-mobile-360p.webm')} type="video/webm" />
      </Display>
    </video>
    <Link href="/todas-modalidades">
      <a className={styles['main-content']}>
        <div className={styles['text-container']}>
          <Typography style={{ fontFamily: 'saira' }} className={styles['title']} weight="bold">
            Aproveite! Entrega Grátis com o cupom: <strong className={styles['title__strong']}>BESTENTREGA</strong>
          </Typography>
        </div>
        <div className={styles['buttons-container']}>
          <Button className={styles['button']} size={ButtonSize.LG}>
            Assine Agora
          </Button>
        </div>
      </a>
    </Link>
  </div>
);

export default MainBanner;
