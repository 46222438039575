import { ProductCard as DSProductCard } from '@objectedge/ziyou-storefront-ds';
import Link from 'next/link';
import { useCallback } from 'react';
import { ProductInterface, ProductStockStatus } from '~/operations';
import GTM from '~/utils/gtm';
import { getPrices } from '~/utils/product';

const mapProductToProductCard = (product: ProductInterface) => {
  const prices = getPrices(product);
  const bestPlan = [...(prices.plans?.options || [])].sort((a, b) => (a.price > b.price ? 1 : -1))[0];
  const bestPrice = bestPlan ? { price: bestPlan.price, title: bestPlan.duration } : undefined;
  const bestPriceTag = bestPlan ? `${Math.floor(bestPlan.discountPct)}% off` : undefined;

  return {
    name: product.name || '',
    image: product.small_image?.url || '',
    href: product.sku === 'club' ? '/club' : `/produto/${product.url_key}` || '',
    price: prices,
    bestPrice,
    promoTag: bestPriceTag ? bestPriceTag : prices.discountTag,
    stockStatus:
      product?.stock_status === ProductStockStatus.OutOfStock && product.attribute_set_name !== 'Estação de Musculação'
        ? 'Indisponível'
        : '',
    // rating: product.rating,
  };
};
export interface ProductCardProps {
  product: ProductInterface;
  pos?: number;
  context?: string;
  className?: string;
}

export const ProductCard = ({ product, context, pos, className }: ProductCardProps) => {
  const props = mapProductToProductCard(product);
  const impression = useCallback(GTM.productClick(product, { af: context, idx: pos }), []);

  return (
    <Link href={props.href} passHref>
      <DSProductCard {...props} className={className} onClick={impression} />
    </Link>
  );
};

export default ProductCard;
