import {
  Modal,
  Typography,
  Video as VideoIcon,
  VideoVariation,
  ArrowLeft64,
  ArrowRight64,
  Close24,
} from '@objectedge/ziyou-storefront-ds';
import { useCallback, useContext, useEffect, useState } from 'react';
import Slider from 'react-slick';
import { ProductContext } from '~/context';
import { ProductInterface } from '~/operations';
import { Breakpoint, Display } from '~/utils/Display';
import VideoPlayer from '~/utils/VideoPlayer';
import ProductShare from '../ProductShare';
import styles from './ProductMediaGallery.module.scss';

interface MobileVideoModalProps {
  toggle: React.MouseEventHandler<SVGSVGElement>;
  product: Partial<ProductInterface>;
}

const MobileVideoModal = ({ toggle, product }: MobileVideoModalProps) => (
  <div className={styles.mobileVideoContainer}>
    <div className={styles.header}>
      <Typography variant="body-text-2" className="color-white m-0 flex-grow-1">
        {product?.name}
      </Typography>
      <ProductShare product={product?.name || ''} toggleClassName={styles.share} />
      <Close24 onClick={toggle} className="path-stroke-white" />
    </div>
    <VideoPlayer playing={true} url={product.video_id || ''} width="100%" height="100%" />
  </div>
);

export const ProductMediaGallery = () => {
  const product = useContext(ProductContext);
  const productName = product?.name || 'image';
  const [nav, setNav] = useState<any>(null);
  const [showVideo, setShowVideo] = useState<boolean>(false);
  const [sliderDesktop, setSliderDesktop] = useState<any>(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState<any>(0);

  const [navMobile, setNavMobile] = useState<any>(null);
  const [sliderMobile, setSliderMobile] = useState<any>(null);
  const [selectedImageMobileIndex, setSelectedImageMobileIndex] = useState<any>(0);

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const gallery = [...(product?.media_gallery || [])].sort((a, b) =>
    (a?.position as number) >= (b?.position as number) ? 1 : -1
  );

  const videoUrl = product?.video_id;
  const mediaLength = (gallery.length || 0) + (videoUrl ? 1 : 0);

  const settingsMobile = {
    focusOnSelect: true,
    dots: mediaLength > 1 ? true : false,
    infinite: false,
    speed: 500,
    asNavFor: '.slider-for',
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <ArrowRight64 />,
    prevArrow: <ArrowLeft64 />,
  };

  useEffect(() => {
    setNav(sliderDesktop);
    setNavMobile(sliderMobile);
  });

  const getImage = useCallback(
    (size, mobile = false) => {
      if (gallery.length) {
        var images = gallery[mobile ? selectedImageMobileIndex : selectedImageIndex]?.urls;
        return images && images[size];
      }

      return '';
    },
    [product, selectedImageIndex, selectedImageMobileIndex]
  );

  const settingsDesktop = {
    focusOnSelect: true,
    dots: false,
    infinite: false,
    speed: 500,
    asNavFor: '.slider-for',
    slidesToShow: mediaLength >= 5 ? 5 : mediaLength,
    slidesToScroll: 5,
    nextArrow: <ArrowRight64 />,
    prevArrow: <ArrowLeft64 />,
  };

  return (
    <div className={styles['product-media-gallery']}>
      <Display min={Breakpoint.sm}>
        {showVideo ? (
          <VideoPlayer playing={true} url={videoUrl || ''} width="100%" height="500px" style={{ zIndex: 103 }} />
        ) : (
          <>
            <a onClick={toggleModal}>
              <img src={getImage('medium')} alt={productName} className={styles['image-main']} />
            </a>

            <Modal
              isOpen={modalOpen}
              title={''}
              okHandler={() => {}}
              cancelHandler={toggleModal}
              cancelButtonLabel="Cancelar"
              okButtonLabel="Excluir"
              showCancelButton
              size="lg"
              className={styles['media-modal']}
            >
              <img src={getImage('full')} alt={productName} className={styles['image-main']} />
            </Modal>
          </>
        )}

        <Slider
          {...settingsDesktop}
          className={styles['slider']}
          asNavFor={nav}
          ref={(slider) => setSliderDesktop(slider)}
        >
          {videoUrl && (
            <a className={styles['image-container']} onClick={() => setShowVideo(true)}>
              <VideoIcon variation={VideoVariation.DESKTOP} />
            </a>
          )}
          {gallery?.map((item, index) => (
            <a
              key={item?.urls?.thumb + '' + index}
              onClick={(event) => {
                setShowVideo(false);
                setSelectedImageIndex(index);
                event.preventDefault();
                event.stopPropagation();
              }}
              className={styles['image-container']}
            >
              <img src={`${item?.urls?.thumb}`} alt={productName} />
            </a>
          ))}
        </Slider>
      </Display>

      <Display max={Breakpoint.sm}>
        {showVideo && product && <MobileVideoModal product={product} toggle={() => setShowVideo(false)} />}
        {videoUrl && (
          <a className={styles.mobileVideoIcon} onClick={() => setShowVideo(true)}>
            <VideoIcon variation={VideoVariation.MOBILE} />
          </a>
        )}

        <Slider
          {...settingsMobile}
          className={styles['slider']}
          asNavFor={navMobile}
          beforeChange={(index) => {
            setSelectedImageMobileIndex(index);
          }}
          ref={(slider) => setSliderMobile(slider)}
        >
          {gallery?.map((item, idx) => (
            <a key={item?.urls?.medium + '' + idx} onClick={toggleModal} className={styles['image-mobile']}>
              <img src={`${item?.urls?.medium}`} alt={productName} />
            </a>
          ))}
        </Slider>

        <Modal
          isOpen={modalOpen}
          title={''}
          okHandler={() => {}}
          cancelHandler={toggleModal}
          cancelButtonLabel="Cancelar"
          okButtonLabel="Excluir"
          showCancelButton
          size="lg"
          className={styles['media-modal']}
        >
          <img src={getImage('medium', true)} alt={productName} className={styles['image-main-mobile']} />
          <Slider
            {...settingsMobile}
            className={styles['slider']}
            asNavFor={navMobile}
            ref={(slider) => setSliderMobile(slider)}
            initialSlide={selectedImageMobileIndex}
            dots={false}
            slidesToShow={3}
            slidesToScroll={3}
          >
            {gallery?.map((item, index) => (
              <a
                key={item?.urls?.thumb + '' + index}
                onClick={(event) => {
                  setSelectedImageMobileIndex(index);
                  event.preventDefault();
                  event.stopPropagation();
                }}
                className={styles['image-mobile']}
              >
                <img src={`${item?.urls?.thumb}`} alt={productName} />
              </a>
            ))}
          </Slider>
        </Modal>
      </Display>
    </div>
  );
};

export default ProductMediaGallery;
