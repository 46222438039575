import { Col, Typography } from '@objectedge/ziyou-storefront-ds';
import type { ReactNode } from 'react';
import styles from './SubscriptionExplanation.module.scss';

export interface SubscriptionBlockProps {
  title: string;
  text: string;
  icon: ReactNode;
}

export const SubscriptionBlock = ({ title, text, icon }: SubscriptionBlockProps) => (
  <Col xs={12} lg={4} className={styles['subscription-item']}>
    <div className={styles['icon-container']}>{icon}</div>
    <div>
      <Typography variant="h5" className={styles['subscription-title']}>
        {title}
      </Typography>
      <Typography className={styles['subscription-text']}>{text}</Typography>
    </div>
  </Col>
);

export default SubscriptionBlock;
