import { Button, ButtonSize, LoadingProductCard } from '@objectedge/ziyou-storefront-ds';
import { useContext } from 'react';
import { ProductCard } from '~/components/cards';
import { SearchContext } from '~/context/search';
import { NoResults } from './NoResults';
import styles from './SearchResults.module.scss';

const LoadingCard = () => (
  <div className={styles.card}>
    <LoadingProductCard />
  </div>
);

const ItemCard = ({ item, ...props }: any) => (
  <div key={item.id} className={styles.card}>
    <ProductCard product={item} {...props} />
  </div>
);

export const SearchResults = () => {
  const { products, loading, loadMore } = useContext(SearchContext);

  const { current_page, total_pages } = products?.page_info || {};

  let items = (products?.items || []).map((i, idx) => (
    <ItemCard key={i.id} item={i} context="Search Results" pos={idx} />
  ));
  if (loading) items = items.concat([...Array(12).keys()].map((i) => <LoadingCard key={'l' + i} />));

  return (
    <div className={styles.main}>
      {!items.length ? (
        <NoResults />
      ) : (
        <>
          <div className={styles.cardsContainer}>{items}</div>
          {current_page >= total_pages ? null : (
            <div className={styles.button}>
              <Button size={ButtonSize.LG} onClick={loadMore} disabled={loading}>
                VER MAIS
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SearchResults;
