import { CategoryCardV2 as DSCategoryCard } from '@objectedge/ziyou-storefront-ds';
import Link from 'next/link';
import { CategoryInterface } from '~/operations';

const mapCategoryToCategoryCard = (category: CategoryInterface) => ({
  id: category.id,
  title: category.name || '',
  image: category.image || '',
  href: `/categoria/${category.url_key}` || '',
});

export interface CategoryCardProps {
  category: CategoryInterface;
  pos?: number;
  mini?: boolean;
}

export const CategoryCard = ({ category, pos, mini = false }: CategoryCardProps) => {
  const props = mapCategoryToCategoryCard(category);

  return (
    <Link href={props.href} passHref>
      <DSCategoryCard {...props} mini={mini} lazy />
    </Link>
  );
};

export default CategoryCard;
