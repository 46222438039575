import { Typography } from '@objectedge/ziyou-storefront-ds';
import { useState } from 'react';
import { Menu } from '~/types/menu';
import { scrollTo } from '~/utils/scroll';
import styles from './SideMenu.module.scss';

export interface SideMenuProps {
  menuList: Menu[];
}

export const SideMenu = ({ menuList }: SideMenuProps) => {
  const [activeMenu, setActiveMenu] = useState(menuList[0].path);

  return (
    <div className={styles['side-menu']}>
      <div className={styles['sticky-side-menu']}>
        {menuList.map((menu) => (
          <a
            key={menu.id}
            href={`#${menu.path}`}
            className="text-decoration-none"
            onClick={(e) => {
              scrollTo(e, menu.path);
              setActiveMenu(menu.path);
            }}
          >
            <div className={styles[activeMenu === menu.path ? 'side-menu-item-active' : 'side-menu-item']}>
              <Typography className={styles.item} variant={activeMenu === menu.path ? 'h5' : 'subtitle-1'}>
                {menu.name}
              </Typography>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default SideMenu;
