import { GenericCarousel, Typography } from '@objectedge/ziyou-storefront-ds';
import CustomerPostCard from '~/components/cards/CustomerPostCard';
import { CategoryInterface } from '~/operations';

export interface CustomerPostCarouselProps {
  title?: string;
  className?: string;
  items: Array<CategoryInterface>;
}

export const CustomerPostCarousel = ({ title, className, items }: CustomerPostCarouselProps) => {
  if (!items) return null;
  const cards = items.map((category, idx) => (
    <CustomerPostCard key={category.id || idx} category={category} pos={idx} />
  ));

  const settings = {
    infinite: true,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={className}>
      {title && (
        <Typography variant="h2" className="mb-4">
          {title}
        </Typography>
      )}
      <GenericCarousel cards={cards} settings={settings} />
    </div>
  );
};

export default CustomerPostCarousel;
