import { Typography } from '@objectedge/ziyou-storefront-ds';
import clsx from 'clsx';
import { getMediaPath } from '~/utils/getPublicPath';
import ClassClubCarousel from '../ClassClubCarousel';
import styles from './ClubClasses.module.scss';

const classes = [
  {
    id: 6,
    image: getMediaPath('/classes/class/class-5.webp'),
    name: 'Spinning - 50 min',
    variation: 'Intensidade forte',
  },
  {
    id: 7,
    image: getMediaPath('/classes/class/class-7.webp'),
    name: 'Elíptico - 40 min',
    variation: 'Intensidade Leve',
  },
  {
    id: 8,
    image: getMediaPath('/classes/class/class-8.webp'),
    name: 'Esteira - 30 min',
    variation: 'Intensidade Intermediário',
  },
  {
    id: 9,
    image: getMediaPath('/classes/class/class-9.webp'),
    name: 'Spinning - 50 min',
    variation: 'Intensidade Leve',
  },
  {
    id: 10,
    image: getMediaPath('/classes/class/class-10.webp'),
    name: 'Esteira - 30 min',
    variation: 'Intensidade forte',
  },
  {
    id: 1,
    image: getMediaPath('/classes/class/class-6.webp'),
    name: 'Remo - 40 min',
    variation: 'Intensidade forte',
  },
  {
    id: 2,
    image: getMediaPath('/classes/class/class-1.webp'),
    name: 'Spinning - 50 min',
    variation: 'Intensidade intermediária',
  },
  {
    id: 3,
    image: getMediaPath('/classes/class/class-2.webp'),
    name: 'Remo - 50 min',
    variation: 'Intensidade leve',
  },
  {
    id: 4,
    image: getMediaPath('/classes/class/class-3.webp'),
    name: 'Elíptico - 50 min',
    variation: 'Intensidade Forte',
  },
  {
    id: 5,
    image: getMediaPath('/classes/class/class-4.webp'),
    name: 'Esteira - 40 min',
    variation: 'Intensidade forte',
  },
];

export interface ClubClassesProps {
  title?: string;
  subText?: string;
  className?: string;
}

export const ClubClasses = ({
  title = 'Diversas aulas online para você escolher',
  subText = 'Tenha acesso a todo o conteúdo para treinar quando quiser! Baixe nosso app ZiYou Club.',
  className,
}: ClubClassesProps) => (
  <div className={clsx(styles['main'], className)}>
    <div className={clsx(styles['heading'], 'text-center mb-4 pb-3')}>
      <Typography variant="h1" className="mb-2 d-none d-lg-block d-md-block">
        {title}
      </Typography>
      <Typography variant="h4" className="mb-2 d-block d-lg-none d-md-none">
        {title}
      </Typography>
      <Typography variant="body-text-1" className="mb-0">
        {subText}
      </Typography>
    </div>
    <div className={styles['slider']}>
      <ClassClubCarousel classes={classes} />
    </div>
  </div>
);

export default ClubClasses;
