import { makeVar, ReactiveVar, useReactiveVar } from '@apollo/client';
import { useEffect, useState } from 'react';

const LOCAL_STORAGE_PREFIX = 'ziyou.';

export interface PersistentReactiveVar<T> extends ReactiveVar<T> {
  (newValue?: T): T;
}

/**
 * Create a reactive variable that is persisted in localstorage
 *
 * @param name Id in the localstorage
 * @param initialValue Initial value if we don't have none in the localstorage
 */
export function makePersistentVar<T>(name: string, initialValue?: T): ReactiveVar<T | undefined> {
  try {
    const value = localStorage.getItem(`${LOCAL_STORAGE_PREFIX}${name}`);
    if (value !== null) {
      initialValue = JSON.parse(value);
    }
  } catch (e) {}
  const variable = makeVar(initialValue);

  const listener = (newValue) => {
    if (newValue === undefined || newValue === null) localStorage.removeItem(`${LOCAL_STORAGE_PREFIX}${name}`);
    else localStorage.setItem(`${LOCAL_STORAGE_PREFIX}${name}`, JSON.stringify(newValue));
    variable.onNextChange(listener);
  };

  variable.onNextChange(listener);

  return variable;
}

/**
 * Consume a value from a reactive variable and clean up the variable
 * This is meant to pass messages between components only once, like when moving to another page and needing
 * to present a temporary alert there
 *
 * @param variable The reactive variable to listen
 * @return The content on the reactive variable
 */
export function useConsumeVar<T>(variable: ReactiveVar<T>): T {
  const variableValue = useReactiveVar(variable);
  const [value, setValue] = useState<T>(variableValue);

  useEffect(() => {
    if (variableValue === undefined) return;
    setValue(variableValue);
    variable(undefined);
  }, [variableValue]);

  return value;
}
