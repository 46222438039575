import clsx from 'clsx';
import { getMediaPath } from '~/utils/getPublicPath';
import styles from './Apps.module.scss';

export interface AppsProps {
  className?: string;
  hideWhenUnavailable?: boolean;
}

const PLAY_STORE_URL = process.env.APP_PLAY_STORE_LINK;
const APPLE_STORE_URL = process.env.APP_APPLE_STORE_LINK;

export const Apps = ({ className, hideWhenUnavailable = false }: AppsProps) => {
  if (hideWhenUnavailable && !PLAY_STORE_URL && APPLE_STORE_URL) return null;

  return (
    <div className={clsx(styles.appLink, className)}>
      <a target="_blank" href={APPLE_STORE_URL} className={styles.appIcon}>
        <img src={getMediaPath(`/badges/app-store.svg`)} alt="Disponível na App Store" loading="lazy" />
      </a>
      <a target="_blank" href={PLAY_STORE_URL} className={clsx(styles.appIcon)}>
        <img src={getMediaPath(`/badges/play-store.svg`)} alt="Disponível no Google Play" loading="lazy" />
      </a>
    </div>
  );
};

export default Apps;
