import { makeVar } from '@apollo/client';
import { GetCustomerDataQuery } from '~/operations';
import { makePersistentVar } from '~/utils/variables';

export interface PlacedOrder extends GetCustomerDataQuery {
  schedule: { date?: String };
  payment: any;
}

export const cartId = makePersistentVar<string>('cartId');
export const placedOrder = makeVar<PlacedOrder | null>(null);

export const cachePolicies = {
  Query: {
    fields: {
      cart: {
        read() {
          return cartId();
        },
      },
    },
  },
};
