import { ProductCarousel } from '~/components/marketing';
import { ProductInterface, useGetSuggestedProductsQuery } from '~/operations';

const fixedProductIds = ['ZU100ES', 'ZU100BV', 'ZU100BH', 'ZU100BS', 'ZU100EL', 'ZU100EM', 'ZU100RE'];

export const ProductSuggestions = () => {
  const { data } = useGetSuggestedProductsQuery({ variables: { productIds: fixedProductIds } });
  if (!data?.products?.items) return null;

  return <ProductCarousel title="Você também pode gostar" items={data.products.items as ProductInterface[]} />;
};

export default ProductSuggestions;
