import { Typography } from '@objectedge/ziyou-storefront-ds';
import { MutableRefObject, useContext } from 'react';
import { ProductContext } from '~/context';
import { scrollToRef } from '~/utils/scroll';
import styles from './ProductBasicDescription.module.scss';

export interface ProductBasicDescriptionProps {
  descriptionRef: MutableRefObject<any>;
}

export const ProductBasicDescription = ({ descriptionRef }: ProductBasicDescriptionProps) => {
  const product = useContext(ProductContext) || {};
  const { short_description } = product;

  return short_description ? (
    <div className={styles['product-basic-description']}>
      <Typography variant="h5" className={styles['description-title']}>
        Descrição
      </Typography>
      <div className={styles['description-text']} dangerouslySetInnerHTML={{ __html: short_description?.html }} />

      <a onClick={() => scrollToRef(descriptionRef)} className={styles['description-link']}>
        <Typography variant="button" className={styles['description-link-text']}>
          + Mais Detalhes
        </Typography>
      </a>
    </div>
  ) : null;
};

export default ProductBasicDescription;
