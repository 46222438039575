import { Button, ButtonSize, ButtonVariant, Col, Row, Typography, VoltageInput } from '@objectedge/ziyou-storefront-ds';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { MutableRefObject, useCallback, useContext, useEffect, useState } from 'react';
import { ProductPrice } from '~/components/product-details';
import { ProductContext, ProductDetailsPageContext } from '~/context';
import { ConfigurableProduct, ConfigurableProductOptionsValues, ConfigurableVariant } from '~/operations';
import { cartId } from '~/state/cart';
import { customerToken } from '~/state/customer';
import { scrollToProductRef } from '~/utils/scroll';
import { useCartContext } from '~/utils/useCart';
import styles from './ProductSelector.module.scss';

export interface ProductSelectorProps {
  voltagemRef: MutableRefObject<any>;
}
interface VoltageOptionProps {
  option: ConfigurableProductOptionsValues;
  variants: any | undefined | null;
  selectedOption: number | undefined | null;
  setSelectedOption: Function;
}

const AVISE_ME_URL = process.env.AVISE_ME_URL;
const CORPORATIVO_URL = process.env.CORPORATIVO_URL;

const VoltageOption = ({ option, variants, selectedOption, setSelectedOption }: VoltageOptionProps) => {
  const { label, value_index } = option;
  const variant = variants?.filter((variant: ConfigurableVariant) => {
    return variant?.attributes?.find((attribute: any) => {
      return attribute?.value_index === value_index;
    });
  });

  const hasProductWithStock = variant?.some((variantProduct: ConfigurableVariant) => {
    return variantProduct?.product?.stock_status === 'IN_STOCK';
  });

  function toggleActive() {
    setSelectedOption(value_index);
  }

  return (
    <VoltageInput
      defaultVoltage={label || ''}
      onClick={toggleActive}
      select={selectedOption === value_index}
      unSelect={!hasProductWithStock}
    />
  );
};

export const ProductSelector = ({ voltagemRef }: ProductSelectorProps) => {
  const { plan, selectVariant } = useContext(ProductDetailsPageContext);
  const { actions: cartActions } = useCartContext();
  const product = useContext(ProductContext) || {};
  const { sku = '', configurable_options, variants, stock_status, attribute_set_name } = product as ConfigurableProduct;

  const router = useRouter();
  const [selectedSku, setSelectedSku] = useState<string>('');
  const [selectedOption, setSelectedOption] = useState<number | null>();
  const [quantity, setQuantity] = useState<number>(1);
  const [maxQuantity, setMaxQuantity] = useState<number | null>();
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const [buttonErrorMessage, setButtonErrorMessage] = useState<string>();

  const voltageOption = configurable_options?.find((option: any) => option?.attribute_code === 'voltage');

  // Get states from cache
  const currentCartId = cartId();
  const currentCustomerToken = customerToken();

  // If product has no child skus, set main sku as selected
  useEffect(() => {
    if (!configurable_options && sku) {
      setSelectedSku(sku);
    }
  }, [configurable_options, sku]);

  // Set selected sku after selecting voltage
  useEffect(() => {
    setButtonErrorMessage('');
    if (!selectedOption || !variants) return;
    const selectedVariant = variants.find((variant) =>
      variant?.attributes?.some((attribute) => attribute?.value_index === selectedOption)
    );
    setSelectedSku(selectedVariant?.product?.sku || sku || '');
  }, [selectedOption, sku, variants]);

  // Notify PDP when we select a sku from the variants
  useEffect(() => {
    const variant = (variants || []).find((v) => v?.product?.sku === selectedSku);
    selectVariant(variant?.product);
  }, [selectedSku]);

  // Set selected sku after selecting voltage
  useEffect(() => {
    setMaxQuantity(null);
    if (!selectedSku) return;
    const skuVariant = (variants || []).find((v) => v?.product?.sku === selectedSku);
    if (!skuVariant) setMaxQuantity(product.stock_level || 0);
    else setMaxQuantity(skuVariant.product?.stock_level || 0);
  }, [selectedSku]);

  // Set button disabled if quantity is over max quantity or product is unavailable
  useEffect(() => {
    setButtonDisabled(false);
    if (stock_status !== 'IN_STOCK' || (maxQuantity && quantity > maxQuantity)) {
      setButtonDisabled(true);
    }
  }, [quantity, maxQuantity, stock_status]);

  const handleAddToCart = useCallback(() => {
    setButtonErrorMessage('');
    if (!selectedSku) {
      scrollToProductRef(voltagemRef);
      setButtonErrorMessage('Selecione uma voltagem');
      return;
    }

    if (configurable_options) {
      setButtonDisabled(true);
      cartActions.addToCart({
        payload: {
          parentSku: sku || '',
          qty: quantity,
          skuFromEspecificOption: selectedSku,
          plan: plan ? { id: plan.optionId, value_string: plan.id + '' } : undefined,
        },
        successCallback: () => {
          setButtonDisabled(false);
          router.push('/carrinho');
        },
        errorCallback: () => {
          setButtonDisabled(false);
        },
      });
      return;
    }

    if (!sku) return;

    setButtonDisabled(true);
    cartActions.addToCart({
      payload: {
        qty: quantity,
        sku: sku || '',
        plan: plan ? { id: plan.optionId, value_string: plan.id + '' } : undefined,
      },
      successCallback: () => {
        setButtonDisabled(false);
        router.push('/carrinho');
      },
      errorCallback: () => {
        setButtonDisabled(false);
      },
    });
  }, [sku, configurable_options, quantity, selectedSku, currentCartId, currentCustomerToken, plan]);

  return (
    <div className={styles['product-selector']} ref={voltagemRef}>
      <div className={styles['button-container']}>
        {stock_status === 'IN_STOCK' ? (
          <div className={styles.tab}>
            <ProductPrice className={'display-tab'} />
            <Button
              className={styles['add-to-cart-button']}
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.LG}
              onClick={handleAddToCart}
              disabled={buttonDisabled}
            >
              Assinar
            </Button>
          </div>
        ) : attribute_set_name === 'Estação de Musculação' ? (
          <Link href={CORPORATIVO_URL || ''}>
            <a>
              <Button className={styles['add-to-cart-button']} variant={ButtonVariant.PRIMARY} size={ButtonSize.LG}>
                Saiba mais
              </Button>
            </a>
          </Link>
        ) : (
          <Link href={AVISE_ME_URL || ''}>
            <a>
              <Button className={styles['add-to-cart-button']} variant={ButtonVariant.PRIMARY} size={ButtonSize.LG}>
                Avise-me
              </Button>
            </a>
          </Link>
        )}
      </div>
      {voltageOption && (
        <Typography className={styles['product-selector-title']} variant="h5">
          Escolha
        </Typography>
      )}

      <Row className={styles['options-row']}>
        {voltageOption && (
          <Col xs={6}>
            <Typography className={styles['caption-2']} variant="caption-2">
              Voltagem
            </Typography>
            <div
              className={
                buttonErrorMessage
                  ? `${styles['voltage-options-container']} ${styles['voltage-error-border']}`
                  : styles['voltage-options-container']
              }
            >
              {voltageOption?.values?.map((voltageOption: any) => (
                <VoltageOption
                  key={voltageOption.value_index}
                  option={voltageOption}
                  variants={variants}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                />
              ))}
            </div>
            {buttonErrorMessage && (
              <Typography variant="caption-1" className={styles['error-message']}>
                {buttonErrorMessage}
              </Typography>
            )}
          </Col>
        )}
      </Row>
    </div>
  );
};

export default ProductSelector;
