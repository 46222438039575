import { QuantityInput, Typography } from '@objectedge/ziyou-storefront-ds';
import { useEffect, useState } from 'react';
import styles from './ProductQuantitySelector.module.scss';

export interface ProductQuantitySelectorProps {
  quantity: number;
  maxQuantity: number | null | undefined;
  setQuantity: Function;
}

export const ProductQuantitySelector = ({ quantity, maxQuantity, setQuantity }: ProductQuantitySelectorProps) => {
  const [errorMessage, setErrorMessage] = useState<String>('');

  function onIncrease() {
    setQuantity((quantity: number) => quantity + 1);
  }

  function onDecrease() {
    if (quantity === 1) return;
    setQuantity((quantity: number) => quantity - 1);
  }

  function onManualInput(event: React.ChangeEvent<HTMLInputElement>) {
    const { value } = event.target;
    const parsedValue = +value;
    if (parsedValue >= 1) setQuantity(parsedValue);
  }

  useEffect(() => {
    setErrorMessage('');
    if (maxQuantity && quantity > maxQuantity) {
      setErrorMessage(`Apenas ${maxQuantity} disponíveis`);
    }
  }, [maxQuantity, quantity]);

  return (
    <div className={styles['product-quantity-selector']}>
      <QuantityInput
        counter={quantity}
        onIncreaseCount={onIncrease}
        onDecreaseCount={onDecrease}
        onManualInputCount={onManualInput}
      />
      {errorMessage && (
        <Typography variant="caption-1" className={styles['error-message']}>
          {`Apenas ${maxQuantity} disponíveis`}
        </Typography>
      )}
    </div>
  );
};
export default ProductQuantitySelector;
