import {
  Bike64,
  Container,
  Eliptico64,
  Esteiras64,
  Remo64,
  Spinning64,
  Station64,
} from '@objectedge/ziyou-storefront-ds';
import { useRef } from 'react';
import useOnClickOutside from '~/utils/useOnClickOutside';
import CategoryCarousel from './CategoryCarousel';
import styles from './CategoryDropdown.module.scss';

export interface CategoryDropdownProps {
  category?: boolean;
  closeDropdown?: () => void;
}
const categories = [
  {
    url_key: '/categoria/esteiras',
    name: 'Esteiras',
    icon: <Esteiras64 className={styles['icon']} />,
  },
  {
    url_key: '/categoria/bike-spinning',
    name: 'Spinning',
    icon: <Spinning64 className={styles['icon']} />,
  },
  {
    url_key: '/categoria/bicicleta-ergometrica',
    name: 'Bicicleta Ergométrica',
    icon: <Bike64 className={styles['icon']} />,
  },
  {
    url_key: '/categoria/eliptico',
    name: 'Elíptico',
    icon: <Eliptico64 className={styles['icon']} />,
  },
  {
    url_key: '/categoria/remo-indoor',
    name: 'Remo Indoor',
    icon: <Remo64 className={styles['icon']} />,
  },
  {
    url_key: '/categoria/estacao-de-musculacao',
    name: 'Estação de Musculação',
    icon: <Station64 className={styles['icon']} />,
  },
];

export const CategoryDropdown = (props: CategoryDropdownProps) => {
  const open = props.category;
  const optionsRef = useRef<HTMLDivElement>(null);
  useOnClickOutside<HTMLDivElement>(optionsRef, () => (props.closeDropdown ? props.closeDropdown() : null));

  return open ? (
    <div className={styles['main']} ref={optionsRef}>
      <Container>
        <CategoryCarousel items={categories} className={styles.categorySection} />
      </Container>
    </div>
  ) : null;
};

export default CategoryDropdown;
