import {
  Button,
  ButtonVariant,
  Input,
  TextfieldCommonMasks,
  Truck24,
  Typography,
} from '@objectedge/ziyou-storefront-ds';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useGetPostalCodeDetailsLazyQuery } from '~/operations';
import { postalCode as storedPostalCode } from '~/state/user';
import { formatPostalCode } from '~/utils/format';
import styles from '../ProductAvailability.module.scss';

interface ProductAvailabilityFormProps {
  sku: string;
  checkAvailability: Function;
  loading: boolean;
}

interface AvailabilityFormData {
  postalCode: string;
}
const ProductAvailabilityForm = ({ sku, checkAvailability, loading }: ProductAvailabilityFormProps) => {
  const storedCode = storedPostalCode();
  const [fetchPostalCodeDetails, { loading: codeDetailLoading, data }] = useGetPostalCodeDetailsLazyQuery();
  const [defaultValue, setDefaultValue] = useState<string>('');

  const { handleSubmit, control, errors } = useForm<AvailabilityFormData>({
    defaultValues: { postalCode: formatPostalCode(defaultValue) || formatPostalCode(storedCode) },
  });
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (data?.getPostalCodeDetails) {
      checkAvailability({
        variables: {
          postalCode: defaultValue,
          productId: sku,
        },
      });
    }
    if (data?.getPostalCodeDetails === null && !errors?.postalCode) setError('CEP inválido, por favor verificar');
  }, [data, errors]);
  const onSubmit = ({ postalCode }: AvailabilityFormData) => {
    const code = postalCode.replace(/[^\d]/g, '');
    if (code.length !== 8) {
      setError('CEP Inválido');
    } else {
      setDefaultValue(code);
      fetchPostalCodeDetails({ variables: { postalCode: code } });
    }
  };

  return (
    <div className={styles['product-availability']}>
      <Typography variant="h5">Verificar disponibilidade</Typography>
      <div className="d-flex align-items-center">
        <Truck24 className="mr-2" />
        <Typography variant="body-text-2" className="color-dark mb-0">
          Saiba se o produto está disponível em sua região.
        </Typography>
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="d-flex justify-content-between">
        <Controller
          render={({ onChange, onBlur, value }) => (
            <Input
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              placeholder="00000-000"
              mask={TextfieldCommonMasks.cep}
              className={styles['zip-code-input']}
              status={errors.postalCode || error ? 'danger' : 'default'}
              helperText={errors.postalCode?.message || error}
              disabled={loading || codeDetailLoading}
            />
          )}
          name="postalCode"
          control={control}
          rules={{ required: true, pattern: { value: /^\d{5}-\d{3}$/, message: 'CEP Inválido' } }}
        />
        <Typography variant="caption-2" className={`color-red-ziyou ${styles['zipcode-search-link']}`}>
          <a href="http://www.buscacep.correios.com.br/sistemas/buscacep/buscaCepEndereco.cfm" target="_blank">
            Não sei meu CEP
          </a>
        </Typography>
        <Button variant={ButtonVariant.DARK} type="submit" disabled={loading || codeDetailLoading}>
          OK
        </Button>
      </form>
    </div>
  );
};

export { ProductAvailabilityForm };
