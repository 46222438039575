import { Typography } from '@objectedge/ziyou-storefront-ds';
import clsx from 'clsx';
import Link from 'next/link';
import styles from './PressReleaseCard.module.scss';

export interface PressReleaseCardProps {
  title: string;
  link: string;
  date: string;
  href: string;
}

export const PressReleaseCard = ({ title, link, date, href }: PressReleaseCardProps) => {
  return (
    <div className={styles.main}>
      <div>
        <Typography variant="h4">{title}</Typography>
        <div className="d-flex">
          <div className={clsx(styles.link, 'mr-1')}>
            <Link href={href || ''}>
              <a target="_blank">{link}</a>
            </Link>
          </div>
          <div>
            <Typography variant="subtitle-1">| Publicado em {date}</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PressReleaseCard;
