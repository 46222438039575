import { Collapse, CollapseVariation, Typography } from '@objectedge/ziyou-storefront-ds';
import { useEffect, useState } from 'react';
import { Menu } from '~/types/menu';
import { scrollTo } from '~/utils/scroll';
import styles from './MenuMobile.module.scss';

export interface MenuMobileProps {
  menuList: Menu[];
  collapse?: boolean;
}

export const MenuMobile = ({ menuList, collapse = false }: MenuMobileProps): JSX.Element => {
  const [menuTitle, setMenuTitle] = useState(menuList[0].name);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(collapse);
  }, [collapse]);
  const toggle = () => setOpen(!open);

  function menuHandler(title: string) {
    setMenuTitle(title);
  }

  return (
    <Collapse isOpen={open} toggle={toggle} title={menuTitle} variation={CollapseVariation.WHITE}>
      <div className={styles['menu-mobile-collapse']}>
        {menuList.map((menu) => (
          <a
            key={menu.id}
            href={`#${menu.path}`}
            onClick={(e) => {
              menuHandler(menu.name);
              scrollTo(e, menu.path);
            }}
          >
            <Typography className={styles.decoration}>{menu.name}</Typography>
          </a>
        ))}
      </div>
    </Collapse>
  );
};

export default MenuMobile;
