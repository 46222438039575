const isProd = process.env.NODE_ENV === 'production';
const cdnHost = process.env.NEXT_PUBLIC_CDN_HOST;

export const getPublicPath = (path: string): string => {
  if (!isProd || !cdnHost) {
    return path;
  }

  return `${cdnHost}/_next/static/public${path}`;
};

export const getMediaPath = (path: string): string => cdnHost + path;
