import { Typography } from '@objectedge/ziyou-storefront-ds';
import Link from 'next/link';
import { CategoryInterface } from '~/operations';
import styles from './CategoryMenuCard.module.scss';

const mapCategoryToCategoryCard = (category: CategoryInterface & { icon: any }) => ({
  id: category.id,
  title: category.name || '',
  icon: category.icon || '',
  href: category.url_key || '',
});

export interface CategoryMenuCardProps {
  category: CategoryInterface & { icon: any };
  pos?: number;
  mini?: boolean;
}

export const CategoryMenuCard = ({ category, pos, mini = false }: CategoryMenuCardProps) => {
  const props = mapCategoryToCategoryCard(category);
  return (
    <Link href={props.href} passHref>
      <a href={props.href} className={styles.categoryBox}>
        <div>{props.icon}</div>
        <Typography> {props.title}</Typography>
      </a>
    </Link>
  );
};

export default CategoryMenuCard;
