import { useRouter } from 'next/router';
import { useCallback } from 'react';

export enum Page {
  club = '/club',
}

export const useNavigation = () => {
  const Router = useRouter();

  const goTo = useCallback((page) => {
    if (!process.browser) return;
    Router.push(page);
  }, []);

  const getQueryParam = useCallback(
    (param: string) => {
      const value = Router.query?.[param] ?? Router.asPath.match(new RegExp(`[&?]${param}=(.*)(&|$)`))?.[1];
      if (value === null) return '';
      if (typeof value === 'string') return value;
      if (typeof value === 'object') return value[0];
      return '';
    },
    [Router.query]
  );

  return { goTo, getQueryParam };
};
