import { Button, Typography } from '@objectedge/ziyou-storefront-ds';
import clsx from 'clsx';
import Link from 'next/link';
import { getMediaPath } from '~/utils/getPublicPath';
import styles from './BikeAndTreadmillPromo.module.scss';

export const BikeAndTreadmillPromo = () => (
  <div className={clsx(styles.main, 'mt-5')}>
    <div className={styles.container}>
      <div className={styles.imageMobileContainer}>
        <img src={getMediaPath('/homepage/marketing-mobile-1.webp')} alt="bike" className="mt-n5" loading="lazy" />
      </div>
      <div className={styles.infoContainer}>
        <Typography variant="h2" className="d-block d-xl-none color-white mt-4">
          Assinando qualquer equipamento você terá acesso ao ZiYou Club com as melhores aulas em uma única plataforma.
        </Typography>
        <Typography variant="h1" className="d-none d-xl-block color-white mt-5">
          Assinando qualquer equipamento você terá acesso ao ZiYou Club com as melhores aulas em uma única plataforma.
        </Typography>
        <Typography variant="body-text-1" className="color-light-grey mt-3">
          Melhore sua qualidade de vida por meio da atividade física que se adapta à sua rotina.
        </Typography>
        <Link href="/todas-modalidades">
          <a className="mb-5">
            <Button className={clsx(styles.btn, 'mt-xl-3 mb-xl-5')}>Assine agora a partir de R$ 129/mês</Button>
          </a>
        </Link>
      </div>
      <div className={styles.imageDesktopTabletContainer}>
        <div className={clsx(styles.promoFirstImage, 'mt-n5')}>
          <picture>
            <source
              srcSet={getMediaPath('/homepage/marketing-tablet-1.webp')}
              type="image/webp"
              media="(min-width: 768px) and (max-width: 991px)"
            />
            <source srcSet={getMediaPath('/homepage/marketing-1.webp')} type="image/webp" media="(min-width:992px)" />

            <img src={getMediaPath('/homepage/marketing-1.webp')} alt="Bike" loading="lazy" />
          </picture>
        </div>
      </div>
    </div>
    <div className={styles.container}>
      <div className={styles.imageDesktopTabletContainer}>
        <div className={clsx(styles.promoSecondImage, 'mb-n5')}>
          <picture>
            <source
              srcSet={getMediaPath('/homepage/marketing-tablet-2.webp')}
              type="image/webp"
              media="(min-width: 768px) and (max-width: 991px)"
            />
            <source srcSet={getMediaPath('/homepage/marketing-2.webp')} type="image/webp" media="(min-width:992px)" />
            <source
              srcSet={getMediaPath('/partner/media/admin/dashboard/tunne-compras-2.jpg')}
              type="image/jpg"
              media="all and (min-width:1201px)"
            />

            <img src={getMediaPath('/homepage/marketing-2.webp')} alt="Esteira" loading="lazy" />
          </picture>
        </div>
      </div>
      <div className={styles.imageMobileContainer}>
        <img src={getMediaPath('/homepage/marketing-mobile-2.webp')} alt="esteira" loading="lazy" />
      </div>
      <div className={styles.infoContainer}>
        <Typography variant="h2" className="d-block d-xl-none color-white mt-4">
          Equipamentos Bluetooth - sua atividade mais completa com monitoramento de dados de forma interativa via
          aplicativos no seu celular. Receba em 48h* pronto para uso!
        </Typography>
        <Typography variant="h1" className="d-none d-xl-block color-white mt-5 mb-4">
          Equipamentos Bluetooth - sua atividade mais completa com monitoramento de dados de forma interativa via
          aplicativos no seu celular. Receba em 48h* pronto para uso!
        </Typography>
        <Typography variant="body-text-1" className="color-light-grey mt-3">
          *consulte o agendamento disponível no checkout da assinatura
        </Typography>
        <Link href="/busca/produtos?s=bluetooth">
          <a className="mb-5">
            <Button className={clsx(styles.btn, 'mt-3 mb-xl-5')}>Quero conhecer</Button>
          </a>
        </Link>
      </div>
    </div>
  </div>
);

export default BikeAndTreadmillPromo;
