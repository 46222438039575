import { Building64, Col, Container, Row, Typography } from '@objectedge/ziyou-storefront-ds';
import styles from './SubscriptionExplanation.module.scss';

export const SubscriptionExplanation = () => (
  <div className={styles['subscription-explanation']}>
    <Container>
      <Row className={styles['subscription-container']}>
        {/* TODO: Icons, titles and text are TBD */}
        <Col xs={12} lg={4} className={styles['subscription-item']}>
          <div className={styles['icon-container']}>
            <Building64 className={styles['subscription-icon']} />
          </div>
          <div>
            <Typography variant="h5" className={styles['subscription-title']}>
              Atendimento
            </Typography>
            <Typography className={styles['subscription-text']}>
              Our diverse team of instructors guide every step of your workout, making each class challenging and fun.
            </Typography>
          </div>
        </Col>

        <Col xs={12} lg={4} className={styles['subscription-item']}>
          <div className={styles['icon-container']}>
            <Building64 className={styles['subscription-icon']} />
          </div>
          <div>
            <Typography variant="h5" className={styles['subscription-title']}>
              Atendimento
            </Typography>
            <Typography className={styles['subscription-text']}>
              Our diverse team of instructors guide every step of your workout, making each class challenging and fun.
            </Typography>
          </div>
        </Col>

        <Col xs={12} lg={4} className={styles['subscription-item']}>
          <div className={styles['icon-container']}>
            <Building64 className={styles['subscription-icon']} />
          </div>
          <div>
            <Typography variant="h5" className={styles['subscription-title']}>
              Atendimento
            </Typography>
            <Typography className={styles['subscription-text']}>
              Our diverse team of instructors guide every step of your workout, making each class challenging and fun.
            </Typography>
          </div>
        </Col>

        <Col xs={12} lg={4} className={styles['subscription-item']}>
          <div className={styles['icon-container']}>
            <Building64 className={styles['subscription-icon']} />
          </div>
          <div>
            <Typography variant="h5" className={styles['subscription-title']}>
              Atendimento
            </Typography>
            <Typography className={styles['subscription-text']}>
              Our diverse team of instructors guide every step of your workout, making each class challenging and fun.
            </Typography>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

export default SubscriptionExplanation;
