import { Container, Help24, Logo, LogoSize } from '@objectedge/ziyou-storefront-ds';
import Link from 'next/link';
import styles from './HeaderCompact.module.scss';

export const HeaderCompact = () => (
  <Container fluid className={`fixed-top ${styles.header}`}>
    <Container className="d-flex flex-row align-items-center justify-content-center justify-content-md-between">
      <Link href="/">
        <a id="header-compact-logo">
          <Logo size={LogoSize.SMALL} />
        </a>
      </Link>
      <div className={styles.help}>
        <Link href="/ajuda">
          <a id="header-compact-link-ajuda" className="d-none d-md-block">
            Ajuda
          </a>
        </Link>
        <Link href="/ajuda">
          <a id="header-compact-icon-ajuda" className="d-md-none">
            <Help24 />
          </a>
        </Link>
      </div>
    </Container>
  </Container>
);

export default HeaderCompact;
