import { useContext } from 'react';
import { ProductContext } from '~/context';
import { useCheckAvailabilityLazyQuery } from '~/operations';
import { postalCode as storedPostalCode } from '~/state/user';
import { ProductAvailabilityForm, ProductAvailable, ProductUnavailable } from './subcomponents';

export const ProductAvailability = () => {
  const { sku } = useContext(ProductContext) || {};
  const [checkAvailability, { loading, data }] = useCheckAvailabilityLazyQuery();

  if (!sku) return null;

  if (!data) {
    return <ProductAvailabilityForm sku={sku} loading={loading} checkAvailability={checkAvailability} />;
  } else {
    const { available, postalCode } = data.checkAvailability;
    storedPostalCode(postalCode); // update local storage
    return available ? (
      <ProductAvailable postalCode={postalCode} />
    ) : (
      <ProductUnavailable sku={sku} postalCode={postalCode} />
    );
  }
};

export default ProductAvailability;
