import { MutableRefObject } from 'react';

const HEADER_HEIGHT = 96;
const PRODUCT_ERROR_HEIGHT = 180;

export const scrollToRef = (ref: MutableRefObject<any>) => {
  const isMobile = window.innerWidth < 992;
  if (!!ref.current) {
    window.scrollTo(250, isMobile ? ref.current?.offsetTop : ref.current?.offsetTop - HEADER_HEIGHT);
  }
};

export const scrollToProductRef = (ref: MutableRefObject<any>) => {
  const isMobile = window.innerWidth < 992;
  if (!!ref.current) {
    window.scrollTo(
      250,
      isMobile ? ref.current?.offsetTop + PRODUCT_ERROR_HEIGHT : ref.current?.offsetTop - HEADER_HEIGHT
    );
  }
};

export const scrollTo = (e, anchor) => {
  if (typeof document === 'undefined') return;
  const element = document.getElementById(anchor);
  e.preventDefault();
  window.scrollTo({
    top: (element?.offsetTop || 0) - 60,
    behavior: 'smooth',
  });
};
