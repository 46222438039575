import { GenericCarousel, Typography } from '@objectedge/ziyou-storefront-ds';
import ClassCards from '~/components/cards/ClassCards';
import { CategoryInterface } from '~/operations';

export interface ClassClubCarouselProps {
  title?: string;
  className?: string;
  classes: Array<CategoryInterface>;
}

export const ClassClubCarousel = ({ title, className, classes }: ClassClubCarouselProps) => {
  if (!classes) return null;
  const cards = classes.map((classData, idx) => (
    <ClassCards key={classData.id || idx} classData={classData} pos={idx} />
  ));

  const settings = {
    infinite: true,
    centerMode: false,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 2200,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 7,
        },
      },
      {
        breakpoint: 1900,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerMode: true,
          infinite: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={className}>
      {title && (
        <Typography variant="h2" className="mb-4">
          {title}
        </Typography>
      )}
      <GenericCarousel cards={cards} settings={settings} />
    </div>
  );
};

export default ClassClubCarousel;
