import { useContext } from 'react';
import { Breadcrumbs } from '~/components/structure';
import { ProductContext } from '~/context';
import Display, { Breakpoint } from '~/utils/Display';
import style from './ProductBreadcrumb.module.scss';

const CATEGORY_URL = '/categoria';

const getBreadcrumbs = (categories: any = []) => {
  const resultBreadcrumbs: Array<any> = [];
  let highestLevel = 1;

  if (!categories.length) return;

  categories.forEach((category: any = {}) => {
    const { level } = category;
    if (level > highestLevel) {
      highestLevel = level;
    }
  });

  // Get the first highest level category
  const lastCategory = categories.find((category: any) => {
    const { level } = category;
    return level === highestLevel;
  });

  const { name, id, url_key, breadcrumbs } = lastCategory;

  // if the highest level category has parent categories, insert them into the breadcrumbs array
  if (breadcrumbs && breadcrumbs.length) {
    breadcrumbs.forEach((crumb: any) => {
      const { category_id, category_name, category_url_key, category_level } = crumb;

      resultBreadcrumbs.push({ level: category_level, name: category_name, href: category_url_key, key: category_id });
    });

    resultBreadcrumbs.sort((category1, category2) => {
      return category1.level < category2.level ? -1 : 1;
    });
  }

  // insert last category into the breadcrumbs array, after the parent categories have been sorted
  const lastCategoryCrumb = { name, key: id, href: url_key };
  resultBreadcrumbs.push(lastCategoryCrumb);

  // return the object in the format expected in the DS breadcrumb component
  return resultBreadcrumbs.map((crumb: any) => {
    const { name, key, href } = crumb;
    return { name, key, href: `${CATEGORY_URL}/${href}` };
  });
};

export const ProductBreadcrumb = () => {
  const product = useContext(ProductContext) || {};
  const { categories = [] } = product;
  const displayName: string = product.name || '';

  const crumbs = getBreadcrumbs(categories);

  return crumbs ? (
    <>
      {' '}
      <Display min={Breakpoint.lg}>
        <Breadcrumbs activeName={displayName} crumbs={crumbs} />
      </Display>{' '}
      <Display max={Breakpoint.lg}>
        <Breadcrumbs activeName={displayName} hideActive crumbs={crumbs} listClassName={style['breadcrumbMobile']} />
      </Display>
    </>
  ) : null;
};

export default ProductBreadcrumb;
