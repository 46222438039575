import { Typography } from '@objectedge/ziyou-storefront-ds';
import { Apps } from '~/components/structure';
import styles from './AssociatedAppsIcons.module.scss';

export const AssociatedAppsIcons = () => (
  <div className={styles.associatedAppsIcons}>
    <Typography className={styles.appsText} variant="h5">
      Assinando você ganha acesso ao ZiYou Club
    </Typography>
    <Apps className="mt-2 flex-column" />
  </div>
);

export default AssociatedAppsIcons;
