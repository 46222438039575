import { useApolloClient } from '@apollo/client';
import { Cart32, Container, Logo, LogoSize, Search32, User32 } from '@objectedge/ziyou-storefront-ds';
import Link from 'next/link';
import { useCallback, useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { CategoryDropdown, InputSearch } from '~/components/search';
import { useRevokeCustomerTokenMutation } from '~/operations';
import { useNavigation } from '~/utils/navigation';
import { useCartContext } from '~/utils/useCart';
import { useCookiesContext } from '~/utils/useCookies';
import styles from './HeaderDesktop.module.scss';

export const HeaderDesktop = () => {
  const { goTo } = useNavigation();
  const { actions: cartActions } = useCartContext();
  const { state: cookie, actions: cookieActions } = useCookiesContext();
  const [search, setSearch] = useState(false);
  const [category, setCategory] = useState(false);
  const [dropdownUser, setDropdownUser] = useState(false);
  const { cache } = useApolloClient();
  const CORPORATIVO_URL = process.env.CORPORATIVO_URL;
  const PRODUTOS_PROPRIOS_URL = process.env.PRODUTOS_PROPRIOS_URL;

  const [revokeCustomerToken] = useRevokeCustomerTokenMutation();

  const logout = useCallback(() => {
    revokeCustomerToken();
    cookieActions.updateUserTokenCookie('');
    cookieActions.updateUserEmail('');
    cache.evict({ id: 'ROOT_QUERY', fieldName: 'customer' });
    cartActions.initCart(true); // reset cart
  }, []);

  const handleOpenCategoryMenu = (e, category) => {
    e.preventDefault();
    setCategory(category);
  };

  return (
    <Container fluid className={`fixed-top ${styles.main}`}>
      <Container className={styles.header}>
        <div className={styles.logo}>
          <Link href="/">
            <a id="header-logo">
              <Logo size={LogoSize.SMALL} />
            </a>
          </Link>
        </div>
        <div className={styles.links}>
          <Link href="/sobre-a-ziyou">
            <a id="header-link-conheca">Sobre</a>
          </Link>
          <Link href="/como-funciona">
            <a id="header-link-assinaturas">Como funciona</a>
          </Link>
          <Link href="/club">
            <a id="header-link-assinaturas">ZiYou Club</a>
          </Link>

          <div onMouseLeave={(e) => handleOpenCategoryMenu(e, false)}>
            <Link href="/todas-modalidades">
              <a
                id="header-link-todas-modalidades"
                className={category ? `${styles.productColor} ${styles.productActive}` : styles.productColor}
                onMouseOver={(e) => handleOpenCategoryMenu(e, true)}
              >
                Todas as Modalidades
              </a>
            </Link>
            <CategoryDropdown
              category={category}
              closeDropdown={() => {
                setCategory(false);
              }}
            />
          </div>
          <Link href={PRODUTOS_PROPRIOS_URL || ''}>
            <a target="_blank" id="header-link-nova-bike">
              PRODUTOS PRÓPRIOS
            </a>
          </Link>
          <Link href={CORPORATIVO_URL || ''}>
            <a id="header-link-corporativo">Para empresas</a>
          </Link>
        </div>
        <div className={styles.icons}>
          <Search32 id="header-icon-search" className="cursor-pointer" onClick={() => setSearch(!search)} />
          {cookie.token ? (
            <div
              className="cursor-pointer"
              onMouseOver={(e) => {
                e.preventDefault();
                setDropdownUser(true);
              }}
              onMouseLeave={(e) => {
                e.preventDefault();
                setDropdownUser(false);
              }}
            >
              <Dropdown isOpen={dropdownUser} toggle={() => setDropdownUser(!dropdownUser)} className="d-inline">
                <DropdownToggle tag="span">
                  <User32 id="header-icon-user" className="cursor-pointer" />
                </DropdownToggle>
                <DropdownMenu className={styles.userMenu}>
                  <Link href="/minha-conta/minhas-assinaturas">
                    <a id="header-link-minhas-assinaturas">
                      <DropdownItem>Minhas Assinaturas</DropdownItem>
                    </a>
                  </Link>
                  <Link href="/minha-conta">
                    <a id="header-link-meu-perfil">
                      <DropdownItem>Meu Perfil</DropdownItem>
                    </a>
                  </Link>
                  <Link href="/">
                    <a id="header-link-sair">
                      <DropdownItem onClick={logout}>Sair</DropdownItem>
                    </a>
                  </Link>
                </DropdownMenu>
              </Dropdown>
            </div>
          ) : (
            <Link href="/login">
              <a id="header-icon-user" className="cursor-pointer">
                <User32 />
              </a>
            </Link>
          )}
          <Link href="/carrinho">
            <a id="header-mini-cart" className="position-relative">
              <Cart32 items={cartActions.getTotalItemsInTheCart()} />
            </a>
          </Link>
        </div>
      </Container>
      <InputSearch
        search={search}
        closeDropdown={() => {
          setSearch(!search);
        }}
      />
    </Container>
  );
};

export default HeaderDesktop;
