import { Typography } from '@objectedge/ziyou-storefront-ds';
import clsx from 'clsx';
import { useContext } from 'react';
import { ProductContext } from '~/context';
import { ProductDetailsPageContext } from '~/context/pdp';
import { formatCurrency } from '~/utils/format/currency';
import { getPrices } from '~/utils/product';
import styles from './ProductPrice.module.scss';

export interface ProductPriceProps {
  className?: string;
}

export const ProductPrice = ({ className = '' }: ProductPriceProps) => {
  const { variant } = useContext(ProductDetailsPageContext);
  const product = useContext(ProductContext) || {};
  const prices = getPrices(variant || product);

  const { plan } = useContext(ProductDetailsPageContext);

  return (
    <div className={clsx(styles.main, styles[className])}>
      {plan ? (
        <>
          <div>
            <Typography variant="subtitle-2" className="color-grey mb-0 font-weight-500">
              {'De '}
              <del>{formatCurrency(prices.listPrice, false)}</del>
              {' Por'}
            </Typography>
          </div>
          <div className={styles.price}>
            <Typography variant="h2" className="mb-0">
              {formatCurrency(plan.price)}
              <span className={styles.monthstyle}>/mês</span>
            </Typography>
            <Typography variant="caption-2">Assinatura mínima de {plan.duration} meses</Typography>
          </div>
        </>
      ) : (
        <>
          <div>
            <Typography variant="h5" className="mb-1">
              Assinatura mensal
            </Typography>
            {prices.fromPrice && (
              <Typography variant="subtitle-2" className="color-grey mb-0 font-weight-500">
                A partir de
              </Typography>
            )}
            {!prices.fromPrice && prices.salePrice && prices.listPrice && (
              <Typography variant="subtitle-2" className="color-grey mb-0 font-weight-500">
                {'De '}
                <del>{formatCurrency(prices.listPrice, false)}</del>
                {' Por'}
              </Typography>
            )}
            {!prices.fromPrice && !prices.salePrice && prices.listPrice && (
              <Typography variant="subtitle-2" className="color-grey mb-0 font-weight-500">
                {'Por'}
              </Typography>
            )}
          </div>
          {prices.fromPrice && (
            <div className={styles.price}>
              <Typography variant="h2">
                {formatCurrency(prices.fromPrice)}
                <span className={styles.monthstyle}>/mês</span>
              </Typography>
            </div>
          )}

          {!prices.fromPrice && !prices.salePrice && prices.listPrice && (
            <div className={styles.price}>
              <Typography variant="h2">
                {`${formatCurrency(prices.listPrice)}`}
                <span className={styles.monthstyle}>/mês</span>
              </Typography>
            </div>
          )}

          {!prices.fromPrice && prices.salePrice && prices.listPrice && (
            <div className={styles.price}>
              <Typography variant="h2">
                {`${formatCurrency(prices.salePrice)}`}
                <span className={styles.monthstyle}>/mês</span>
              </Typography>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ProductPrice;
