import { Pin24, Truck24, Typography } from '@objectedge/ziyou-storefront-ds';
import { formatPostalCode } from '~/utils/format';
import styles from '../ProductAvailability.module.scss';

interface ProductAvailableProps {
  city?: string;
  postalCode: string;
}

const ProductAvailable = ({ city, postalCode }: ProductAvailableProps) => (
  <div className={styles['product-availability']}>
    <Typography variant="h5">Estoque disponível</Typography>
    <div className="d-flex align-items-center mb-3">
      <Truck24 className="mr-2" />
      <Typography variant="body-text-2" className="color-dark mb-0">
        O produto está disponível para entrega na sua região
      </Typography>
    </div>
    <div className="d-flex align-items-center mb-4">
      <Pin24 className="mr-2" />
      <Typography variant="body-text-2" className="color-dark mb-0">
        Enviar para {city} <b>{formatPostalCode(postalCode)}</b>
      </Typography>
    </div>
  </div>
);

export { ProductAvailable };
