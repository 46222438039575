import clsx from 'clsx';
import Link from 'next/link';
import { Button, Typography } from '@objectedge/ziyou-storefront-ds';
import Display, { Breakpoint } from '~/utils/Display';
import { getMediaPath } from '~/utils/getPublicPath';
import styles from './ZiYouBikePromo.module.scss';

export const ZiYouBikePromo = () => {
  const NOVA_BIKE_URL = process.env.NOVA_BIKE_URL;

  return (
    <div className={styles['main']}>
      <div className={styles['main__container']}>
        <div className={styles['card']}>
          <img className={styles['card__image']} src={getMediaPath('/homepage/banner-ziyou-cyclist.webp')} />
        </div>
        <div className={styles['card']}>
          <img className={styles['card__image']} src={getMediaPath('/homepage/banner-ziyou-content-guide.webp')} />
        </div>

        <div className={styles['card']}>
          <img className={styles['card__image']} src={getMediaPath('/homepage/banner-ziyou-cycle-equipment.webp')} />
        </div>
      </div>

      <div className={styles['infoContainer']}>
        <Typography variant="h2" className="color-white mb-2">
          <span className="color-red-ziyou">Bike ZiYou Bluetooth</span>, sua experiência com o pedal nunca mais será a
          mesma.
        </Typography>
        <Typography variant="body-text-1" className="color-light-grey mt-2">
          Saiba mais sobre todas as características da nova bike ZiYou, e compre agora!
        </Typography>
        <Link href={NOVA_BIKE_URL || ''}>
          <a target="_blank" className={styles['anchor']}>
            saiba mais
          </a>
        </Link>
      </div>
    </div>
  );
};

export default ZiYouBikePromo;
