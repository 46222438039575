import { Typography } from '@objectedge/ziyou-storefront-ds';
import { useContext } from 'react';
import { ProductContext } from '~/context';
import styles from './ProductSummary.module.scss';

export const ProductSummary = () => {
  const { name } = useContext(ProductContext) || {};

  let displayName = name || '';

  //Regex to find ZUXXX in the product name
  const regex = /(Z)(U)(\d{3,})/g;

  // Add a span between the "U" of ZUXXX to allow different color
  if (name && regex.exec(name)) {
    displayName = displayName?.replace(regex, '$1<span class="color-red-ziyou">$2</span>$3');
  }

  return (
    <div className={styles['product-summary']}>
      <Typography className={styles['product-name']} variant="h1">
        <span dangerouslySetInnerHTML={{ __html: displayName }} />
      </Typography>
    </div>
  );
};

export default ProductSummary;
