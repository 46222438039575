import { Breadcrumb, BreadcrumbProps } from '@objectedge/ziyou-storefront-ds';
import clsx from 'clsx';
import Head from 'next/head';
import { useRouter } from 'next/router';
import styles from './Breadcrumbs.module.scss';

const useBreadcrumbs = () => {
  const Router = useRouter();
  return ({ name, key, href }) => {
    return {
      name,
      key,
      href,
      onClick: (e) => {
        e.preventDefault();
        Router.push(href);
      },
    };
  };
};

const crumbScriptBuilder = (crumbs) => ({
  '@context': 'https://schema.org/',
  '@type': 'BreadcrumbList',
  itemListElement: crumbs.map((crumb, idx) => ({
    '@type': 'ListItem',
    position: idx + 1,
    name: crumb.name,
    item: `${process.env.COMMERCE_URL}${crumb.href}`,
  })),
});

export const Breadcrumbs = (props: BreadcrumbProps) => {
  const router = useRouter();
  const builder = useBreadcrumbs();
  const crumbs = props.crumbs.map((c) => builder(c));
  const home = builder({ name: 'Home', key: 'home', href: '/' });
  const current = builder({
    name: props.activeName,
    key: 'current',
    href: router.asPath,
  });
  const crumbScript = crumbScriptBuilder([home, ...crumbs, current]);

  return (
    <>
      <Head>
        <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(crumbScript) }} />
      </Head>
      <Breadcrumb
        {...props}
        crumbs={crumbs}
        listClassName={clsx(styles.breadcrumbs, props.listClassName)}
        home={home}
      />
    </>
  );
};

export default Breadcrumbs;
