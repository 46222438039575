import styles from './CategorySummary.module.scss';
import { Typography } from '@objectedge/ziyou-storefront-ds';
import { CategoryTree } from '~/operations';

export interface CategorySummaryProps {
  category?: Partial<CategoryTree>;
}

export const CategorySummary = ({ category }: CategorySummaryProps) => {
  return category ? (
    <div className={styles['main']}>
      <Typography className={styles['title']} variant="h1" weight="bold">
        {category.name}
      </Typography>
      <hr className={styles['border']} />
    </div>
  ) : null;
};

export default CategorySummary;
