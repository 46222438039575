import { CustomerPostCard as DSCustomerPostCard } from '@objectedge/ziyou-storefront-ds';
import Link from 'next/link';
import { CategoryInterface } from '~/operations';

const mapCategoryToCustomerPostCard = (category: CategoryInterface) => ({
  id: category.id,
  title: category.name || '',
  image: category.image || '',
  href: `${category.url_key}` || '',
});

export interface CustomerPostCardProps {
  category: CategoryInterface;
  pos?: number;
  mini?: boolean;
}

export const CustomerPostCard = ({ category, pos, mini = false }: CustomerPostCardProps) => {
  const props = mapCategoryToCustomerPostCard(category);

  return (
    <Link href={props.href} passHref>
      <DSCustomerPostCard {...props} mini={mini} lazy />
    </Link>
  );
};

export default CustomerPostCard;
