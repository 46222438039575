import Head from 'next/head';
import { CategoryInterface, ProductInterface } from '~/operations';

export interface SeoTagsProps {
  fallbackTitle?: string;
  category?: CategoryInterface | null;
  product?: ProductInterface | null;
}

export const SeoTags = ({ category, product, fallbackTitle }: SeoTagsProps) => {
  let metaTitle, metaDescription, metaKeywords;

  if (product?.meta_title) metaTitle = product.meta_title;
  else if (category?.meta_title) metaTitle = category.meta_title;
  if (product?.meta_description) metaDescription = product.meta_description;
  else if (category?.meta_description) metaDescription = category.meta_description;
  if (product?.meta_keyword) metaKeywords = product.meta_keyword;
  else if (category?.meta_keywords) metaKeywords = category.meta_keywords;

  if (product && !metaDescription) {
    metaDescription = product?.short_description?.html
      ?.replace(/(<([^>]+)>)|(\s)/gi, ' ')
      ?.replace(/ {1,}/g, ' ')
      ?.trim();
  }

  return (
    <Head>
      {(metaTitle || fallbackTitle) && <title>{metaTitle || fallbackTitle}</title>}
      {metaTitle && <meta name="title" content={metaTitle} />}
      {metaDescription && <meta name="description" content={metaDescription} />}
      {metaKeywords && <meta name="keywords" content={metaKeywords} />}
      {product && (
        <>
          <meta property="og:type" content="website" />
          <meta property="og:title" content={metaTitle || product?.name || ''} />
          <meta property="og:quote" content={`Olha o que eu achei na ZiYou! ${product?.name}`} />
          <meta property="og:image" content={product?.description_image?.url || ''} />
          <meta property="og:url" content={(typeof window !== 'undefined' && window.location.href) || ''} />
          <meta property="og:description" content={metaDescription} />
        </>
      )}
    </Head>
  );
};

export default SeoTags;
