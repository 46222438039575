/**
 * Formats a CNPJ or CPF document number
 * @param documentNumber String with the number
 * @param documentType The type of the document
 * @returns String formatted document
 */
export const formatDocument = (documentNumber?: string | null, documentType?: string | null): string => {
  if (!documentNumber) return '';

  const number = documentNumber.replace(/[^\d]/g, '');
  if (documentType === 'CPF') {
    return number.padStart(11, '0').replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
  } else if (documentType === 'CNPJ') {
    return number.padStart(14, '0').replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
  }
  return documentNumber;
};

export default formatDocument;
