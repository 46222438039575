import ReactPlayer, { ReactPlayerProps } from 'react-player/lazy';

const defaultConfig = {
  youtube: {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters?playerVersion=HTML5
      cc_loader_policy: 0, // disable closed-caption
      disableKb: 1, // disable keyboard shortcuts
      iv_load_policy: 3, // disable
      modestbranding: 1, // modest youtube logo
    },
  },
};

export const VideoPlayer = (props: ReactPlayerProps) => {
  return <ReactPlayer width="100%" height="100%" {...props} config={defaultConfig} />;
};

export default VideoPlayer;
