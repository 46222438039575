import { Container, Logo, LogoSize } from '@objectedge/ziyou-storefront-ds';
import Link from 'next/link';
import styles from './HeaderMaintenance.module.scss';

export const HeaderMaintenance = () => (
  <Container fluid className={`fixed-top ${styles.header}`}>
    <Container className="d-flex flex-row align-items-center justify-content-center justify-content-md-between">
      <Link href="/">
        <a id="header-maint-logo">
          <Logo size={LogoSize.SMALL} />
        </a>
      </Link>
    </Container>
  </Container>
);

export default HeaderMaintenance;
