import { useContext } from 'react';
import { ProductCarousel } from '~/components/marketing';
import { ProductContext } from '~/context';
import { ProductInterface } from '~/operations';
import styles from './RelatedProducts.module.scss';

export const RelatedProducts = () => {
  const product = useContext(ProductContext);
  if (!product || !product.related_products || !product.related_products.length) {
    // TODO Remove this padding after we add other items between the product description
    // and the footer
    return <div className="my-5"></div>;
  }

  return (
    <ProductCarousel
      className={styles['main']}
      title="Complete seu treino"
      items={product.related_products as ProductInterface[]}
    />
  );
};

export default RelatedProducts;
