import {
  Collapse,
  Container,
  Facebook48,
  Insta48,
  Linkedin48,
  Logo,
  Phone24,
  Typography,
} from '@objectedge/ziyou-storefront-ds';
import Link from 'next/link';
import { Apps } from '~/components/structure';
import styles from './FooterMobile.module.scss';

const FooterMobile = () => {
  const FACEBOOK_URL = process.env.FACEBOOK_URL;
  const LINKEDIN_URL = process.env.LINKEDIN_URL;
  const INSTAGRAM_URL = process.env.INSTAGRAM_URL;
  return (
    <Container fluid className={styles.footer}>
      <div className={styles.logoContainer}>
        <Logo />
      </div>
      <div>
        <Collapse title="Sobre" collapseClassName={styles.collapse}>
          <Link href="/como-funciona">
            <a>Assinaturas</a>
          </Link>
          <Link href="/como-funciona">
            <a>Nossos Parceiros</a>
          </Link>
          <Link href="/sobre-a-ziyou ">
            <a>Conheça a ZiYou</a>
          </Link>
          <Link href="/sobre-a-ziyou ">
            <a>Imprensa</a>
          </Link>
        </Collapse>
        <Collapse title="Ajuda" collapseClassName={styles.collapse}>
          <Link href="/ajuda">
            <a>FAQ</a>
          </Link>
          <Link href="/termos-e-condicoes">
            <a>Termos e Condições</a>
          </Link>
          <Link href="/ajuda">
            <a>Nosso serviço de entrega</a>
          </Link>
          <Link href="/politica-de-privacidade">
            <a>Política de Privacidade</a>
          </Link>
        </Collapse>
        <Collapse title="Contato" collapseClassName={styles.collapse}>
          <div className="px-3 pb-2">
            Nossos atendentes estão disponíveis
            <br />
            online para tirar suas dúvidas. Das 08h às
            <br />
            18h de segunda a sexta, e sábado das 08h às 14h, exceto feriados.
            <br />
            <Phone24 className="mr-1" />
            3003-4596
            <br />
          </div>
        </Collapse>
      </div>
      <Apps className="justify-content-center" hideWhenUnavailable />
      <div className={styles.social}>
        <Link href={LINKEDIN_URL || ''}>
          <a target="_blank">
            <Linkedin48 />
          </a>
        </Link>
        <Link href={INSTAGRAM_URL || ''}>
          <a target="_blank">
            <Insta48 />
          </a>
        </Link>
        <Link href={FACEBOOK_URL || ''}>
          <a target="_blank">
            <Facebook48 />
          </a>
        </Link>
      </div>
      <Typography variant="h6" className={`${styles.brandingText} text-center`}>
        Copyright ©{new Date().getFullYear()} - {new Date().getFullYear()}{' '}
        <a href="https://www.ziyou.com.br" className={`${styles.brandingText}`}>
          https://www.ziyou.com.br
        </a>
        , TODOS OS DIREITOS RESERVADOS. Todo o conteúdo do site, todas as fotos, imagens, logotipos, marcas, dizeres,
        som, software, conjunto imagem, layout, trade dress, aqui veiculados são de propriedade exclusiva da ZIYOU
        Inter. É vedada qualquer reprodução, total ou parcial, de qualquer elemento de identidade, sem expressa
        autorização. A violação de qualquer direito mencionado implicará na responsabilização cível e criminal nos
        termos da Lei. ZIYOU SP - CNPJ: 38.449.479/0001-10 - Rua Jardim Ivone, 17 - Conj 82; Vila Mariana - CEP
        04105-020 - São Paulo - SP. ZIYOU AM - CNPJ: 38.449.479/0003-82 - Rua Marques de Muritiba, 528, Quadra B15, Lote
        9, Part11, Box A PQ das Laranjeiras - Bairro Flores - CEP 69058-140 - Manaus - AM. A inclusão no carrinho não
        garante o preço e/ou a disponibilidade do produto. Caso os produtos apresentem divergências de valores, o preço
        válido é o exibido na tela de pagamento. Vendas sujeitas à análise e disponibilidade de estoque
      </Typography>
    </Container>
  );
};

export { FooterMobile };
