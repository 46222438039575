import { Typography, Row, Col, Container } from '@objectedge/ziyou-storefront-ds';
import { SubscriptionCategories } from '~/components/marketing';
import { ProductSuggestions } from '~/components/product-details';

const NoResults = () => {
  return (
    <>
      <Container>
        <Row className="justify-content-center">
          <Typography className="mb-5 pb-5" variant="body-text-1">
            Não foi encontrado nenhum resultado para a sua busca.
          </Typography>
        </Row>
      </Container>
      <ProductSuggestions />
    </>
  );
};

export { NoResults };
