import { Form, Loader, Search64, Typography } from '@objectedge/ziyou-storefront-ds';
import { useRouter } from 'next/router';
import { FormEvent, useRef, useState } from 'react';
import { GetSearchResultsDocument, GetSearchResultsQuery } from '~/operations';
import { useLazyQueryWithPromise } from '~/utils/useLazyQueryWithPromise';
import useOnClickOutside from '~/utils/useOnClickOutside';
import styles from './InputSearch.module.scss';

export interface InputSearchProps {
  search?: boolean;
  closeDropdown?: () => void;
}

export const InputSearch = (props: InputSearchProps) => {
  const Router = useRouter();
  const [loading, setLoading] = useState(false);
  const searchQuery = useLazyQueryWithPromise<GetSearchResultsQuery>(GetSearchResultsDocument);
  const optionsRef = useRef<HTMLDivElement>(null);
  useOnClickOutside<HTMLDivElement>(optionsRef, () => (props.closeDropdown ? props.closeDropdown() : null));

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const pageSize = 48;
    const search = e.target['search']?.value;
    if (!search) return;

    setLoading(true);
    try {
      const { data } = await searchQuery({ variables: { currentPage: 1, pageSize, search } });
      if (data.products?.redirect) {
        Router.push(data.products.redirect);
      } else {
        Router.push({ pathname: '/busca/produtos', query: { s: search } });
      }
    } catch (e) {}

    setLoading(false);
    props.closeDropdown ? props.closeDropdown() : null;
  };

  if (loading) return <Loader />;
  if (!props.search) return null;
  return (
    <div className={styles['main']} ref={optionsRef}>
      <Form onSubmit={handleSubmit} className={styles['form']}>
        <Typography className={styles['title']} variant="subtitle-2" weight="bold">
          Procurando por:
        </Typography>
        <input
          autoComplete="off"
          type="text"
          id="search"
          className={styles['input']}
          placeholder="Que tal esteiras ?"
          autoFocus
        />
        <button id="header-search-button" className={styles['search']} type="submit">
          <Search64 className={styles['search-icon']} />
        </button>
        <hr className={styles['border']} />
      </Form>
    </div>
  );
};

export default InputSearch;
