import Head from 'next/head';

export const ProductDataScript = ({ name, image, descriptionHtml, price, stockStatus }) => {
  const url = (typeof window !== 'undefined' && window.location.href) || '';
  let description = descriptionHtml
    ?.replace(/(<([^>]+)>)|(\s)/gi, ' ')
    ?.replace(/ {1,}/g, ' ')
    ?.trim();

  let data = {
    name,
    image,
    description,
    price,
  };
  return (
    <Head>
      <script key="product-ld" type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }} />
    </Head>
  );
};

export default ProductDataScript;
