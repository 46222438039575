import { Container, Row } from '@objectedge/ziyou-storefront-ds';
import type { ReactNode } from 'react';
import styles from './SubscriptionExplanation.module.scss';

export interface SubscriptionExplanationProps {
  children: ReactNode;
}

export const SubscriptionExplanation = ({ children }: SubscriptionExplanationProps) => (
  <div className={styles['subscription-explanation']}>
    <Container>
      <Row className={styles['subscription-container']}>{children}</Row>
    </Container>
  </div>
);

export default SubscriptionExplanation;
