import { Building64, Container, Home64, Ticket64, Typography } from '@objectedge/ziyou-storefront-ds';
import styles from './SubscriptionOption.module.scss';

export const SubscriptionOption = () => (
  <div>
    <Container className={styles['main']}>
      <div className={styles['card']}>
        <Home64 className={styles['icon']} />
        <div className={styles['text-container']}>
          <Typography style={{ fontFamily: 'saira' }} className={styles['title']} weight="bold">
            Na sua casa
          </Typography>
          <Typography className={styles['text']} variant="body-text-1">
            Faça a sua atividade física no conforto da sua casa, com toda praticidade do nosso serviço de assinatura. À
            sua disposição, os melhores equipamentos, fáceis de posicionar e de guardar no seu espaço.
          </Typography>
        </div>
      </div>
      <div className={styles['card']}>
        <Building64 className={styles['icon']} />
        <div className={styles['text-container']}>
          <Typography style={{ fontFamily: 'saira' }} className={styles['title']} weight="bold">
            No seu condomínio
          </Typography>
          <Typography className={styles['text']} variant="body-text-1">
            Monte o seu centro fitness com os melhores equipamentos do mercado. Modalidades variadas que atendem
            diversos objetivos dentro do seu espaço disponível.
          </Typography>
        </div>
      </div>
      <div className={styles['card']}>
        <Ticket64 className={styles['icon']} />
        <div className={styles['text-container']}>
          <Typography style={{ fontFamily: 'saira' }} className={styles['title']} weight="bold">
            Na sua empresa
          </Typography>
          <Typography className={styles['text']} variant="body-text-1">
            Aproveite a conveniência de oferecer aos seus colaboradores os melhores equipamentos para atividade física.
          </Typography>
        </div>
      </div>
    </Container>
  </div>
);

export default SubscriptionOption;
