import { ClassCard as DSClassCard } from '@objectedge/ziyou-storefront-ds';
import { Page } from '~/utils/navigation';
import { useRouter } from 'next/router';
import Link from 'next/link';

const mapCategoryToClassCard = (classData: any) => ({
  id: classData.id,
  name: classData.name || '',
  image: classData.image || '',
  variation: classData.variation || '',
});

export interface ClassCardsProps {
  classData: any;
  pos?: number;
  mini?: boolean;
}

export const ClassCards = ({ classData, pos, mini = false }: ClassCardsProps) => {
  const href = useRouter().pathname === Page.club ? '' : Page.club;
  const props = mapCategoryToClassCard(classData);

  return href ? (
    <Link href={href} passHref>
      <DSClassCard {...props} lazy />
    </Link>
  ) : (
    <DSClassCard {...props} lazy />
  );
};

export default ClassCards;
