import { GenericCarousel, Typography } from '@objectedge/ziyou-storefront-ds';
import { CategoryCard } from '~/components/cards';
import { CategoryInterface } from '~/operations';

export interface CategoryCarouselProps {
  title?: string;
  className?: string;
  items: Array<CategoryInterface>;
}

export const CategoryCarousel = ({ title, className, items }: CategoryCarouselProps) => {
  if (!items) return null;

  const cards = items.map((category, idx) => <CategoryCard key={category.id || idx} category={category} pos={idx} />);

  const settings = {
    slidesToShow: 6,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1008,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 418,
        settings: {
          slidesToShow: 1.7,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={className}>
      {title && (
        <Typography variant="h2" className="mb-4">
          {title}
        </Typography>
      )}
      <GenericCarousel cards={cards} settings={settings} />
    </div>
  );
};

export default CategoryCarousel;
