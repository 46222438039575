import { Container, GenericCarousel, Typography } from '@objectedge/ziyou-storefront-ds';
import { ProductCard } from '~/components/cards';
import { ProductInterface } from '~/operations';
import styles from './ProductCarousel.module.scss';

export interface ProductCarouselProps {
  title?: string;
  className?: string;
  items: Array<ProductInterface>;
}

export const ProductCarousel = ({ title, className, items }: ProductCarouselProps) => {
  if (!items || items.length < 1) return null;

  const cards = items.map((product, idx) => (
    <ProductCard className="border-0 w-auto mx-3" key={product.id || idx} product={product} pos={idx} />
  ));

  const settings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 512,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={className}>
      <Container>
        {title && (
          <Typography variant="h2" className={styles['section-title'] + ' ' + 'mb-4 pb-3 ml-3'}>
            {title}
          </Typography>
        )}
        <GenericCarousel cards={cards} settings={settings} />
      </Container>
    </div>
  );
};

export default ProductCarousel;
