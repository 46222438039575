import React from 'react';
import useWindowDimensions from './useWindowDimensions';

const breakpoints = { xs: 0, sm: 576, md: 767, lg: 991, xl: 1199, xxl: 1439 };

export enum Breakpoint {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  xxl = 'xxl',
}

export interface DisplayProps {
  min?: Breakpoint;
  max?: Breakpoint;
  children: React.ReactNode;
}

export const useBreakpoint = (): Breakpoint => {
  const { width } = useWindowDimensions();
  let breakpoint = Breakpoint.xs;
  Object.entries(breakpoints).forEach(([key, value]) => {
    if ((width || 0) > value) breakpoint = Breakpoint[key];
  });

  return breakpoint;
};

export const Display = ({ min, max, children }: DisplayProps) => {
  const { width } = useWindowDimensions();

  const w = width || 0;
  const minW = min ? breakpoints[min] : 0;
  const maxW = max ? breakpoints[max] : Infinity;
  const display = w > minW && w <= maxW;

  return display ? <>{children}</> : null;
};

export default Display;
