import { GenericCarousel, Typography } from '@objectedge/ziyou-storefront-ds';
import { CategoryMenuCard } from '~/components/cards';
import { CategoryInterface } from '~/operations';

export interface CategoryCarouselProps {
  title?: string;
  className?: string;
  items: Array<CategoryInterface & { icon: any }>;
}

export const CategoryCarousel = ({ title, className, items }: CategoryCarouselProps) => {
  if (!items) return null;

  const cards = items.map((category, idx) => (
    <CategoryMenuCard key={category.id || idx} category={category} pos={idx} />
  ));

  const settings = {
    dots: true,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <div className={className}>
      {title && (
        <Typography variant="h2" className="mb-4">
          {title}
        </Typography>
      )}
      <GenericCarousel cards={cards} settings={settings} />
    </div>
  );
};

export default CategoryCarousel;
