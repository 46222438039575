import styles from './SearchSummary.module.scss';
import { Typography } from '@objectedge/ziyou-storefront-ds';

export interface SearchSummaryProps {
  name: string;
  results?: any;
}

export const SearchSummary = ({ name, results }: SearchSummaryProps) => {
  return (
    <div className={styles['main']}>
      <Typography className={styles['subtitle']} variant="subtitle-2">
        Você procurou por:
      </Typography>
      <Typography className={styles['title']} variant="h1" weight="bold">
        {name}
      </Typography>
      {results !== undefined && (
        <Typography className={styles['results']} variant="subtitle-1">
          {results} resultados
        </Typography>
      )}
      <hr className={styles['border']} />
    </div>
  );
};

export default SearchSummary;
