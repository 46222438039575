import clsx from 'clsx';
import Display, { Breakpoint } from '~/utils/Display';
import styles from './Header.module.scss';
import { HeaderCompact, HeaderDesktop, HeaderMaintenance, HeaderMobile } from './subcomponents';

export interface HeaderProps {
  compact?: boolean;
  maintenance?: boolean;
}

export const Header = ({ compact = false, maintenance = false }: HeaderProps) => {
  function renderHeader() {
    const getHeader = () => {
      if (!maintenance && compact) return <HeaderCompact />;
      if (maintenance && !compact) return <HeaderMaintenance />;
      return (
        <>
          <Display max={Breakpoint.lg}>
            <HeaderMobile />
          </Display>
          <Display min={Breakpoint.lg}>
            <HeaderDesktop />
          </Display>
        </>
      );
    };

    return (
      <div className={clsx(styles.headerContainer, (compact || maintenance) && styles.compact)}>{getHeader()}</div>
    );
  }

  return renderHeader();
};

export default Header;
