import { Container, Typography } from '@objectedge/ziyou-storefront-ds';
import { getMediaPath } from '~/utils/getPublicPath';
import CustomerPostCarousel from '../CustomerPostCarousel';
import styles from './CustomerPost.module.scss';

const categories = [
  {
    url_key: 'https://www.instagram.com/p/CRWgdn3NGUA/',
    image: getMediaPath('/homepage/social-customer-foto-1-carrossel.webp'),
  },
  {
    url_key: 'https://www.instagram.com/p/CQ1jjM2H3VX/',
    image: getMediaPath('/homepage/social-customer-foto-2-carrossel.webp'),
  },
  {
    url_key: 'https://www.instagram.com/p/CQRTmvDLXxS/',
    image: getMediaPath('/homepage/social-customer-foto-3-carrossel.webp'),
  },
  {
    url_key: 'https://www.instagram.com/p/CO-emtcnq8g/',
    image: getMediaPath('/homepage/social-customer-foto-4-carrossel.webp'),
  },
  {
    url_key: 'https://www.instagram.com/p/CN52J16MtCp/',
    image: getMediaPath('/homepage/social-customer-foto-5-carrossel.webp'),
  },
  {
    url_key: 'https://www.instagram.com/p/CS2f2t5NnFF/',
    image: getMediaPath('/homepage/social-customer-foto-6-carrossel.webp'),
  },
];

export const CustomerPost = () => (
  <div className={styles['main']}>
    <Container fluid="xl" className={styles['slider-container']}>
      <div className="text-center mb-5">
        <Typography variant="h1">Comunidade ZiYou nas redes sociais</Typography>
      </div>
    </Container>
    <div className={styles['slider']}>
      <CustomerPostCarousel items={categories} />
    </div>
  </div>
);

export default CustomerPost;
